import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import { EmptyLocalStorage, Logout } from "../services/auth/auth.service";
import { toast } from "react-toastify";
import {
  BusinessesIcon,
  CategoriesIcon,
  DashboardIcon,
  DrinkIcon,
  FaqIcon,
  LogoutIcon,
  NotificationIcon,
  PagesIcon,
  PasswordIcon,
  StatisticsIcon,
  SubscriptionIcon,
  Payment,
  TimeZoneIcon,
} from "../utils/Svg";
import { HiOutlineUsers } from "react-icons/hi";
import { IoCreateOutline } from "react-icons/io5";

function SideBar() {
  let history = useHistory();
  async function handleLogout() {
    toast.success("logged out successfully");
    await EmptyLocalStorage();
    history.push("/");
    // await Logout().then(async (data) => {
    //     if (data.status) {
    //         toast.success(data.message);
    //         await EmptyLocalStorage();
    //         history.push('/');
    //     } else {
    //         toast.error(data.message);
    //     }
    // }).catch((error) => {
    //     if (error.response.status == 401) {
    //         EmptyLocalStorage()
    //         history.push('/');
    //     } else {
    //         console.log(error);
    //         return toast.error(error.response.data.message);
    //     }
    // })
  }
  return (
    <div className="d-flex flex-column flex-shrink-0 p-3">
      <ul className="nav nav-pills flex-column mb-auto">
        <li className="nav-item">
          <NavLink
            to="/dashboard"
            className="nav-link "
            activeClassName="active"
          >
            <DashboardIcon />
            Dashboard
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/users" className="nav-link  " activeClassName="active">
            <HiOutlineUsers />
            Users
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to="/businesses"
            className="nav-link  "
            activeClassName="active"
          >
            <BusinessesIcon />
            Businesses
          </NavLink>
        </li>
        {/*<li className="nav-item">*/}
        {/*  <NavLink*/}
        {/*    to="/categories-management"*/}
        {/*    className="nav-link  "*/}
        {/*    activeClassName="active"*/}
        {/*  >*/}
        {/*    <CategoriesIcon />*/}
        {/*    Categories*/}
        {/*  </NavLink>*/}
        {/*</li>*/}

        {/*<li className="nav-item">*/}
        {/*  <NavLink*/}
        {/*    to="/packages-and-subscription"*/}
        {/*    className="nav-link  "*/}
        {/*    activeClassName="active"*/}
        {/*  >*/}
        {/*    <SubscriptionIcon />*/}

        {/*    Package & Subscription*/}
        {/*  </NavLink>*/}
        {/*</li>*/}
        <li className="nav-item">
          <NavLink
            to="/statistics-and-analytics"
            className="nav-link  "
            activeClassName="active"
          >
            <StatisticsIcon />
            Statistics and Analytics
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to="/payments"
            className="nav-link  "
            activeClassName="active"
          >
            <Payment />
            Payments
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to="/push-notification"
            className="nav-link  "
            activeClassName="active"
          >
            <NotificationIcon />
            Push Notification
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to="/statistics-and-analytics"
            className="nav-link  "
            activeClassName="active"
          >
            <IoCreateOutline />
            Create User or Businesses
          </NavLink>
        </li>
        {/* <li className="nav-item">
          <NavLink
            to="/manage-users"
            className="nav-link  "
            activeClassName="active"
          >
            <HiOutlineUsers  />
            Users
          </NavLink>
        </li> */}

        <li className="nav-item">
          <NavLink to="/faqs" className="nav-link  " activeClassName="active">
            <FaqIcon />
            FAQ's
          </NavLink>
        </li>

        {/* <li>
          <NavLink
            to="/ads"
            className="nav-link "
            activeClassName="active"
          >
            <RiAdvertisementLine />
            Ads
          </NavLink>
        </li> */}
        {/* <li>
          <NavLink
            to="/suggestions"
            className="nav-link "
            activeClassName="active"
          >
            <RiBookOpenLine />
            Suggestions
          </NavLink>
        </li> */}
        {/* <li>
          <NavLink
            to="/contents"
            className="nav-link "
            activeClassName="active"
          >
            <BiBookContent />
            Contents
          </NavLink>
        </li> */}
        <li>
          <NavLink to="/pages" className="nav-link " activeClassName="active">
            <PagesIcon />
            Pages
          </NavLink>
        </li>

        {/*<li>*/}
        {/*    <NavLink to="/push-notification" className="nav-link " activeClassName="active">*/}
        {/*        <AiOutlineBell/>*/}
        {/*        Push Notification*/}
        {/*    </NavLink>*/}
        {/*</li>*/}
        {/* <li>
          <NavLink
            to="/change-password"
            className="nav-link "
            activeClassName="active"
          >
            <PasswordIcon />
            Change Password
          </NavLink>
        </li>
        <li>
          <NavLink
            to="javascript:;"
            className="nav-link "
            activeClassName="active"
            onClick={handleLogout}
          >
            <LogoutIcon />
            Logout
          </NavLink>
        </li> */}
      </ul>
    </div>
  );
}

export default SideBar;
