import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import { Col, Form, Row } from "react-bootstrap";
import { FadeLoader } from "react-spinners";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { GetPageById, PostAddPage, UpdatePage } from "../../services/pages.service";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { faBullseye } from '@fortawesome/free-solid-svg-icons';

function AddPage(props) {
    let history = useHistory();
    const [data, setData] = useState([]);
    const [formData, setFormData] = useState({});
    const [loader, setLoader] = useState(false);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const { register, setValue, handleSubmit, setError, clearErrors, formState: { errors } } = useForm({
        mode: "onBlur",
    });

    const onSubmit = async data => {

        let dt = {
            slug: data.slug,
            title: data.title,
            content: draftToHtml(convertToRaw(editorState.getCurrentContent())),
        }

        await PostAddPage(dt).then(async (data) => {
            setLoader(false)
            if (data.status) {
                toast.success(data.message);
                history.push('/pages');
            } else {
                setLoader(false)
                toast.error(data.message);
            }
        }).catch((error) => {
            setLoader(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })


    };

    const onEditorStateChange = (newEditorState) => {
        setEditorState(newEditorState)
    }

    return (
        <div className="AddPage">

            <h3 className="page-heading">Add Page</h3>
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Form className="formAddUser" autoComplete="off" role="presentation"
                        onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="slug">
                                    <Form.Label>Slug*</Form.Label>
                                    <Form.Control
                                        {...register('slug', { required: 'Slug is required' })}
                                        type="text"
                                    />
                                    {errors.slug && <Form.Text className="validationText hasError">{errors.slug.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="title">
                                    <Form.Label>Title*</Form.Label>
                                    <Form.Control
                                        {...register('title', { required: 'Title is required' })}
                                        type="text"
                                    />
                                    {errors.title && <Form.Text className="validationText hasError">{errors.title.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mb-3" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Editor
                                    editorState={editorState}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                    onEditorStateChange={onEditorStateChange}
                                    toolbar={{
                                        options: ['blockType', 'list', 'textAlign', 'history'],
                                        blockType: {
                                            inDropdown: true,
                                            options: ['H4'],
                                            className: undefined,
                                            component: undefined,
                                            dropdownClassName: undefined,
                                        }
                                    }}
                                />
                            </Col>
                        </Row>


                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                {loader ? <div className="spin-loader">
                                    <FadeLoader color={"rgb(139, 77, 198)"} height={10} />
                                </div> :
                                    <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"}
                                        value={"Add Page"} />}
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>

        </div>
    )
}

export default AddPage;