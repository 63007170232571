import React, {useEffect, useState} from 'react';

function ConnectAccountFailure(props) {
    const [accountId ,setAccountId] = useState('')
    // function triggerApp() {
    //     const searchParams = new URLSearchParams(window.location.search);
    //     const accountIdParam = searchParams.get('account_id');
    //
    //     if (typeof window.Android !== "undefined") {
    //         window.Android.messageHandlers.successHandler.postMessage(accountIdParam);
    //     }
    //     if (typeof window.webkit !== "undefined") {
    //         window.webkit.messageHandlers.successHandler.postMessage(accountIdParam);
    //     }
    //
    // }
    //

    const handleContinueToApp = () => {
        // Call the PartyLux.postMessage method with the accountIdParam
        const searchParams = new URLSearchParams(window.location.search);
        const accountIdParam = searchParams.get('account_id');
        setAccountId(accountIdParam)
    };


    useEffect(()=>{
        handleContinueToApp()
    },[])


    return (
        <div className="thank-you-page">
            <h1>Declined!</h1>
            <p>Thanks a bunch for filling that out. It means a lot to us, just like you do! We really appreciate you giving us a moment of your time today. Thanks for being you.</p>
            <button onClick={()=>window?.PartyLux?.postMessage(accountId)}>Continue to App</button>
            <p className="fineprint">Copyright ©{new Date().getFullYear()} | All Rights Reserved</p>
        </div>
    );
}

export default ConnectAccountFailure;