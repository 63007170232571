import { apiUrl, constants } from "../../utils/constants";
import { POST } from "../api.service.wrapper";

const storageKey = constants.LOCAL_STORAGE_TOKEN;
const storageUser = constants.LOCAL_STORAGE_USER;

export const Login = async (email, password, role) => {
  const response = await POST(apiUrl.login, { email, password, role });
  await SetToken(response.data.accessToken);
  await SetAuthUser(JSON.stringify(response?.data));
  return response;
  /*const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    "email": "admin46@yopmail.com",
    "password": "admin@libido321",
    "role": "admin"
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow"
  };

  fetch("http://localhost:8080/v1/admin/auth/signin", requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.error(error));*/
};

export const SetToken = (token) => {
  if (token) localStorage.setItem(storageKey, token);
  else localStorage.removeItem(storageKey);
};

export const GetToken = () => {
  return localStorage.getItem(storageKey);
};

export const SetAuthUser = (user_data) => {
  if (user_data) localStorage.setItem(storageUser, user_data);
  else localStorage.removeItem(storageUser);
};

export const GetAuthUser = () => {
  return JSON.parse(localStorage.getItem(storageUser))?.username;
};

export const GetAccessToken = () => {
  return JSON.parse(localStorage.getItem(storageUser))?.access_token;
};

export const Logout = async () => {
  const response = await POST(apiUrl.logout);
  return response;
};

export const EmptyLocalStorage = async () => {
  await localStorage.removeItem(storageUser);
  return await localStorage.removeItem(storageKey);
};

export const GetCurrentUser = () => {
  return JSON.parse(localStorage.getItem(storageUser));
};

export const ForgetPasswordApi = async (email) => {
  const response = await POST(apiUrl.forgot_password, { email });
  return response;
};

export const VerifyOtpApi = async (Otp, email) => {
  const response = await POST(apiUrl.verify_otp, { Otp, email });
  return response;
};

export const ResetPasswordApi = async (
  email,
  password,
) => {
  const response = await POST(apiUrl.reset_password, {
    email,
    password
  });
  return response;
};

export const ChangePasswordApi = async (
 data
) => {
  const response = await POST(apiUrl.change_password, data);
  return response;
};

export const GetUserRole = () => {
  const user = GetAuthUser();
  let role;
  if (user && user.roles) {
    let role_id = user.roles[0].id;
    if (role_id == constants.ROLES.ROLE_ADMIN) {
      role = constants.ROLES.ROLE_ADMIN;
    } else if (role_id == constants.ROLES.ROLE_VENDOR) {
      role = constants.ROLES.ROLE_VENDOR;
    } else if (role_id == constants.ROLES.ROLE_CSA) {
      role = constants.ROLES.ROLE_CSA;
    } else {
      role = constants.ROLES.ROLE_USER;
    }
  }

  return role;
};
