export const BASE_URL = `${process.env.REACT_APP_BASE_URL}`;
export const MOB_BASE_URL = `${process.env.REACT_APP_BASE_URL_MOBILE}`;
export const S3_BASE_URL = "https://s3-presigned-svc.tekstagearea.com/api/v1/";

const roles = {
  ROLE_ADMIN: "1",
  ROLE_USER: "2",
};

const page = {
  PRIVACY_POLICY: "privacy-policy",
  TERMS: "terms-and-conditions",
};

const s3Credential = {
  app_name: "party-lux",
  secretAccessKey: "Pf/uzrZOCI7lzvwgOdP3EolcDcE3uYR3S0HTtPK/",
  accessKeyId: "AKIAUPLSLQUZO2YWP7Y7",
  region: "us-east-1",
  bucketName: "party-lux-backend",
  access: "public",
  app_token: "FdNtVHS6nPR7QZ7Xc5wM",
  _id: "65d657210a8115adf5d62905",
  s3EndPoint: "https://party-lux-backend.s3.us-east-1.amazonaws.com/",
  fileSize: "5000000",
};

const errorMessages = {
  fileSize: "The file size is too large",
  fileSuccess: "file uploaded successfully",
  fileError: "Error in uploading file",
};

const contentType = {
  "image/jpeg": "image",
  "image/jpg": "image",
  "image/png": "image",
  "image/gif": "image",
  "application/pdf": "doc",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "doc",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    "doc",
  "audio/mpeg": "audio",
  "video/mp4": "video",
};

const chatMessage = {
  CONTENT: 0,
  CONTENT_SOFT_DELETE: 1,
  CONTENT_HARD_DELETE: 2,
};

const AdType = {
  IMAGE: "image",
  VIDEO: "video",
};

export const Media = {
  IMAGE: 20,
  VIDEO: 10,
};

export const MediaType = {
  [Media.IMAGE]: "Image",
  [Media.VIDEO]: "Video",
};
export const constants = {
  LOCAL_STORAGE_TOKEN: "AUTH_ACCESS_TOKEN",
  LOCAL_STORAGE_USER: "USER_DATA",
  BASE_URL: process.env.REACT_APP_BASE_URL,
  CSA_URL: process.env.REACT_APP_CSA_BASE_URL,
  ADMIN_URL: process.env.REACT_APP_ADMIN_BASE_URL,
  VENDOR_URL: process.env.REACT_APP_VENDOR_BASE_URL,
  ROLES: roles,
  NOTIFICATIONTYPES: "",
  LOCALE: "en-OM",
  CURRENCY: "OMR",
  PAGES: page,
  PERPAGE: 20,
  ORDER_BY_VALUE: "desc",
  ORDER_BY_COLUMN: "id",
  //IMAGENOTFOUND: 'https://imprint.tekstaging.com/not-found.png',
  IMAGENOTFOUND: "imgNotFound",
  //USERIMAGENOTFOUND: 'https://imprint.tekstaging.com/not-found-user.png',
  USERIMAGENOTFOUND: "imgNotFound",
  COLLECTION: "",
  S3CREDENTIAL: s3Credential,
  ERRORMESSAGES: errorMessages,
  CONTENTTYPE: contentType,
  CHATMESSAGE: chatMessage,
  ADTYPE: AdType,
};

export const genders = {
  MALE: 10,
  FEMALE: 20,
};

export const weight_unit = {
  KG: 10,
  LBS: 20,
};

export const apiUrl = {
  login: "auth/signin",
  logout: "logout",
  toggle_feature: "toggle-feature",
  forgot_password: "auth/forgot-password",
  verify_otp: "auth/verify-otp",
  reset_password: "auth/reset-password",
  change_password: "auth/change-password",
  pages: "cms/content",
  cms: "cms/",
  dashboard: "user/registrationStats",
  dashboard_summary: "dashboard-summary",
  get_s3_token: "files",
  users_list: "user/list",
  users: "user",
  transactions: "transaction",
  settings: "settings",
  business: "business",
  updateBusiness: "business/update-business",
  register: "register",
  ads: "ads",
  suggestions: "suggestions",
  contents: "contents",
  notification: "event/send-announcements",
  // privacy_policy: "page-by-slug/",
  // terms_and_conditions: "page-by-slug/terms-and-conditions",
  categories: "categories",
  timezones: "timezones",
  packages: "packages",
  business_reports: "user/business-by-date",
  user_reports: "user/users-by-date",
  reports: "reports",
  faqs: "faq",
  products: "products",
  send_featured: "send-featured-drink-notification",
};

export const customStyles = {
  rows: {
    style: {
      minHeight: "50px",
    },
  },
  headCells: {
    style: {
      color: "#ffffff",
      fontSize: "12px",
      fontWeight: "500",
      lineHeight: "19px",
      fontFamily: "Poppins",
      padding: "5px",
      textTransform: "uppercase",
      "align-items": "left",
    },
  },
  cells: {
    style: {
      padding: "5px",
      fontSize: "12px",
      fontWeight: "400",
      lineHeight: "19px",
      fontFamily: "Poppins",
      "align-items": "left",
    },
  },
};
export const GENDER_VALUES = {
  MALE: 10,
  FEMALE: 20,
  NONE: 0,
};
export const GENDERS = {
  [GENDER_VALUES.NONE]: "N/A",
  [GENDER_VALUES.MALE]: "Male",
  [GENDER_VALUES.FEMALE]: "Female",
};

export const STATUS_VALUE = {
  ACTIVE: true,
  INACTIVE: false,
  PENDING: null,
};
export const STATUS = {
  [STATUS_VALUE.ACTIVE]: "Approved",
  [STATUS_VALUE.INACTIVE]: "Rejected",
  [STATUS_VALUE.PENDING]: "Pending",
};
export const IS_VERIFIED_VALUE = {
  VERIFIED: 1,
  UN_VERIFIED: 0,
};

export const IS_VERIFIED = {
  [IS_VERIFIED_VALUE.VERIFIED]: "Verified",
  [IS_VERIFIED_VALUE.UN_VERIFIED]: "No",
};
