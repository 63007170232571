import React, { useEffect, useState } from 'react'
import swal from "sweetalert"
import { Link, useHistory } from "react-router-dom"
import { Button, Col, Dropdown, DropdownButton, FormControl, InputGroup, Row, Form } from "react-bootstrap"
import { AddIconOutline, MenuDots, SearchIcon } from "../../components/SvgIcons"
import { toast } from "react-toastify";
import DataTable, {createTheme} from 'react-data-table-component';
import BarLoader from "react-spinners/BarLoader";
import { customStyles } from "../../utils/constants";
import { DeletePage, GetPages } from "../../services/pages.service";
import moment from "moment";
import { EmptyLocalStorage } from "../../services/auth/auth.service";

function Pages() {
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState();
    const [rows, setRows] = useState([]);
    const [keyword, setKeyword] = useState('');

    useEffect(async () => {
        getPages();
    }, [keyword, page, perPage])


    createTheme('solarized', {
        text: {
            primary: '#ffffff',
            secondary: '#929399',
        },
        background: {
            default: '#0d0f0f',
        },
        context: {
            background: '#0d0f0f',
            text: '#929399',
        },
        highlightOnHover: {
            default: 'rgb(139 77 198)',
            text: '#ffffff',
        },
        divider: {
            default: '#929399',
        },
        action: {
            button: 'rgba(0,0,0,.54)',
            hover: 'rgba(0,0,0,.08)',
            disabled: 'rgba(0,0,0,.12)',
        },
    }, 'dark');

    const getPages = async () => {
        setLoading(true);
        await GetPages(keyword, page, perPage).then((result) => {
            if (result.success) {
                if (result.data) {
                    const rowData = [{
                        id: 1 || '-',
                        slug:'party-lux',
                        title: 'partyLux' || '-',
                        content: result.data.partyLux || '-',
                    },
                        {
                            id: 2 || '-',
                            slug:'privacy-policay',
                            title:'privacyPolicy' || '-',
                            content: result.data.privacyPolicy || '-',

                        },
                        {
                            id: 3 || '-',
                            slug:'terms-and-conditions',
                            title: 'termsAndCondition' || '-',
                            content:result.data.termsAndCondition || '-',
                        }
                    ];

                    setPage(result.data?.meta?.current_page)
                    setTotalRows(result.data?.meta?.total);


                    setRows(rowData);
                    setLoading(false)
                }
            } else {
                setLoading(false)
                return toast.error(result.message);
            }
        }).catch((error) => {
            setLoading(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    }
    // =============== User Action Fucntions (Delete/Edit) =============================

    const onEdit = (data) => {
        history.push('/pages/edit-page/' + data.id, {
            data: data
        });
    }

    const onDelete = (id) => {
        swal({
            title: "Are you sure?",
            text: "Are you sure you want to delete this page?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willShip) => {
            if (willShip) {
                await DeletePage(id)
                    .then((result) => {
                        if (result.status) {
                            swal(result.message, {
                                icon: "success",
                            });
                            getPages();
                        } else {
                            toast.error(result.message);
                        }
                    })
                    .catch((error) => {
                        if (error.response && error.response.status === 401) {
                            EmptyLocalStorage();
                            history.push("/");
                        } else {
                            return toast.error(
                                error.response
                                    ? error.response.data.message
                                    : "An error occurred"
                            );
                        }
                    });
            }
        });
    };



    const userActions = (data) => {
        return (
            <DropdownButton align="end" className={"actionDropdown"} id="dropdown-item-button" title={<MenuDots />}>
                <Dropdown.Item as="button" onClick={() => onEdit(data)}>Edit</Dropdown.Item>
                {/* <Dropdown.Item as="button" onClick={() => onDelete(data.id)}>
                    Delete
                </Dropdown.Item> */}
            </DropdownButton>
        )
    }

    const columns = [
        {
            id: 'id',
            name: 'ID',
            sortable: true,
            maxWidth: '60px',
            selector: rowData => rowData.id,
        },
        {
            id: 'slug',
            name: 'Slug',
            sortable: true,
            selector: rowData => rowData.slug,
        },
        {
            id: 'title',
            name: 'Title',
            sortable: true,
            selector: rowData => rowData.title,
        },
        {
            id: 'content',
            name: 'Content',
            maxWidth: '500px',
            sortable: true,
            selector: rowData => rowData.content,
        },
        {
            id: 'action',
            name: 'Action',
            center: true,
            cell: rowData => userActions(rowData)
        },
    ];

    return (
        <div className={"usersPage"}>
            {/*<h1 className="page-heading">Users ({totalRows})</h1>*/}
            <h1 className="page-heading">Pages</h1>
            <div className={"customOrderFilters"}>
                <Row>
                    <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                        {/* <InputGroup className="mb-3 search-group">
                            <InputGroup.Text id="basic-addon1"><SearchIcon /></InputGroup.Text>
                            <FormControl
                                placeholder="Search"
                                aria-label="Search"
                                aria-describedby="Search"
                                maxLength={"255"}
                                onChange={(e) => {
                                    (e.target.value.length > 2) ? setKeyword(e.target.value) : setKeyword(null)
                                }}
                            />
                        </InputGroup> */}
                    </Col>
                    {/* <Col xs={12} sm={12} md={7} lg={7} xl={7} className='mb-3'>
                        <div className='d-flex justify-content-end w-100'>
                            <Link to="/add-page">
                                <Button className={"btn btn-green-theme h40"}>
                                    <AddIconOutline /> Add Pages
                                </Button>
                            </Link>
                        </div>
                    </Col> */}
                </Row>

            </div>
            <div className="theme-dataGrid products-dataGrid">
                <DataTable
                    columns={columns}
                    data={rows}
                    // actions={actionsMemo}
                    // data={data}
                    progressPending={loading}
                    customStyles={customStyles}
                    selectableRows={false}
                    // striped
                    theme='solarized'
                    highlightOnHover
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationPerPage={perPage}
                    onChangePage={(page) => {
                        setPage(page);


                    }}
                    onChangeRowsPerPage={(currentRowsPerPage) => {
                        setPerPage(currentRowsPerPage)
                    }}
                    progressComponent={<BarLoader color={'rgb(139, 77, 198)'} loading={loading} css={'marginTop: 10px'}
                        height={'4'} width={'100%'} />}
                />
            </div>
        </div>
    )
}

export default Pages
