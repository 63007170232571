import React, { useState } from "react";
import { Navbar, NavDropdown, Nav, Container, Dropdown } from "react-bootstrap";
import {
  FaBuysellads,
  FaRegBell,
  FaRegUserCircle,
  FaUsers,
} from "react-icons/fa";
import {
  BusinessesIcon,
  CategoriesIcon,
  DashboardIcon,
  DrinkIcon,
  FaqIcon,
  Logo,
  LogoutIcon,
  PagesIcon,
  PasswordIcon,
  StatisticsIcon,
  SubscriptionIcon,
  TimeZoneIcon
} from "../utils/Svg";
import { EmptyLocalStorage, GetAuthUser, Logout } from "../services/auth/auth.service";
import { toast } from "react-toastify";
import { NavLink, useHistory, useParams, Link } from "react-router-dom";
import { RiDashboardFill, RiLogoutBoxFill, RiPagesFill } from "react-icons/ri";
import { CgProfile } from "react-icons/cg";
import { BsBookFill } from "react-icons/bs";
import { BiBookContent } from "react-icons/bi";
import { MdPassword } from "react-icons/md";
import Offcanvas from "react-bootstrap/Offcanvas";
import { NotificationIcon } from "./SvgIcons";
import {HiOutlineUsers} from "react-icons/hi";
function Header(props) {
  let history = useHistory();
  const { slug } = useParams();
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [notificationStatus, setNotificationStatus] = useState(false);
  async function handleLogout() {
    toast.success("logged out successfully");
    await EmptyLocalStorage();
    history.push("/");
    // await Logout().then(async (data) => {
    //     if (data.status) {
    //         toast.success(data.message);
    //         await EmptyLocalStorage();
    //         history.push('/');
    //     } else {
    //         toast.error(data.message);
    //     }
    // }).catch((error) => {
    //     if (error.response.status == 401) {
    //         EmptyLocalStorage()
    //         history.push('/');
    //     } else {
    //         console.log(error);
    //         return toast.error(error.response.data.message);
    //     }
    // })
  }
  const checkNotifications = () => {
    const notificationCount = 10;
    /*
     *
     *  checking the notification count and set to true
     *
     */
    if (notificationCount > 1) {
      setNotificationStatus(!notificationStatus);
    }
  };

  const navDropdownTitle = (
    <>
      <FaRegUserCircle /> Login
    </>
  );

  let authUser = GetAuthUser();
  authUser = authUser?.replace(/[_-]/g, ' ').replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });


  return (
    <div>
      <Navbar className={"Custom-header d-none d-lg-block"}>
        <Container fluid>
          <Navbar.Brand href={props.url}>
            <Logo width={180} height={50} />
          </Navbar.Brand>

          {slug === "terms-and-conditions" || slug === "privacy-policy" ? (
            ""
          ) : (
            <Dropdown align="end" open={true} className="ms-auto d-none d-lg-flex">
              <Dropdown.Toggle variant="link" id="dropdown-basic" className="profile">
                <CgProfile /> {authUser}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item className="p-0 ">
                  <Link to="/change-password" className="nav-link text-decoration-none" >
                    <PasswordIcon />
                    Change Password
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item className="p-0 " onClick={handleLogout}>
                  <Link to="javascript:;" className="nav-link text-decoration-none" >
                  <LogoutIcon />
                  Logout
                  </Link>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </Container>
      </Navbar>
      {/* Responsive Navbar Starts Here*/}
      <div className="responsive-navbar d-lg-none">
        {[false].map((expand) => (
          <Navbar
            expand="false"
            bg="dark"
            className="navbar-dark imprint-header shadow"
          >
            <Container>
              <Navbar.Brand href="/dashboard">
                <Logo width={180} height={50} />
              </Navbar.Brand>
              <Navbar.Toggle
                className="toggles"
                aria-controls={`offcanvasNavbar-expand-${expand}`}
                onClick={() => setShowOffcanvas(true)}
              />
              <Navbar.Offcanvas
                className="offcanvas-bg"
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
                show={showOffcanvas}
                onHide={() => setShowOffcanvas(false)}
              >
                <Offcanvas.Header closeButton closeVariant={'white'}>
                  <Offcanvas.Title
                    id={`offcanvasNavbarLabel-expand-${expand}`}
                  ></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav className="res-head justify-content-end flex-grow-1 pe-3">
                    <ul className="nav nav-pills flex-column mb-auto">
                      <li className="nav-item">
                        <NavLink
                          to="/dashboard"
                          className="nav-link link-dark"
                          activeClassName="active"
                          onClick={() => setShowOffcanvas(false)}
                        >
                          <DashboardIcon />
                          Dashboard
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                            to="/users"
                            className="nav-link link-dark "
                            activeClassName="active"
                            onClick={() => setShowOffcanvas(false)}
                        >
                          <HiOutlineUsers  />
                          Users
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/businesses"
                          className="nav-link link-dark "
                          activeClassName="active"
                          onClick={() => setShowOffcanvas(false)}
                        >
                          <BusinessesIcon />
                          Businesses
                        </NavLink>
                      </li>


                      <li className="nav-item">
                        <NavLink
                          to="/statistics-and-analytics"
                          className="nav-link link-dark "
                          activeClassName="active"
                          onClick={() => setShowOffcanvas(false)}
                        >
                          <StatisticsIcon />

                          Statistics and Analytics
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/push-notification"
                          className="nav-link link-dark "
                          activeClassName="active"
                          onClick={() => setShowOffcanvas(false)}
                        >
                          <NotificationIcon />

                          Push Notification
                        </NavLink>
                      </li>

                      {/* <li className="nav-item">
          <NavLink
            to="/manage-users"
            className="nav-link link-dark "
            activeClassName="active"
          >
            <HiOutlineUsers />
            Users
          </NavLink>
        </li> */}

                      <li className="nav-item">
                        <NavLink
                          to="/faqs"
                          className="nav-link link-dark "
                          activeClassName="active"
                          onClick={() => setShowOffcanvas(false)}
                        >
                          <FaqIcon />
                          FAQ's
                        </NavLink>
                      </li>

                      {/* <li>
          <NavLink
            to="/ads"
            className="nav-link link-dark"
            activeClassName="active"
          >
            <RiAdvertisementLine />
            Ads
          </NavLink>
        </li> */}
                      {/* <li>
          <NavLink
            to="/suggestions"
            className="nav-link link-dark"
            activeClassName="active"
          >
            <RiBookOpenLine />
            Suggestions
          </NavLink>
        </li> */}
                      {/* <li>
          <NavLink
            to="/contents"
            className="nav-link link-dark"
            activeClassName="active"
          >
            <BiBookContent />
            Contents
          </NavLink>
        </li> */}
                      <li>
                        <NavLink
                          to="/pages"
                          className="nav-link link-dark"
                          activeClassName="active"
                          onClick={() => setShowOffcanvas(false)}
                        >
                          <PagesIcon />
                          Pages
                        </NavLink>
                      </li>
                      {/*<li>*/}
                      {/*    <NavLink to="/push-notification" className="nav-link link-dark" activeClassName="active">*/}
                      {/*        <AiOutlineBell/>*/}
                      {/*        Push Notification*/}
                      {/*    </NavLink>*/}
                      {/*</li>*/}
                      <li>
                        <NavLink
                          to="/change-password"
                          className="nav-link link-dark"
                          activeClassName="active"
                          onClick={() => setShowOffcanvas(false)}
                        >
                          <PasswordIcon />
                          Change Password
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="javascript:;"
                          className="nav-link link-dark"
                          activeClassName="active"
                          onClick={handleLogout}
                        >
                          <LogoutIcon />
                          Logout
                        </NavLink>
                      </li>
                    </ul>
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        ))}
      </div>
      {/* Responsive Navbar Ends Here */}
    </div >
  );
}

export default Header;
