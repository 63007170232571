// SearchBar.js
import React from "react";
import { InputGroup, FormControl } from "react-bootstrap";
import { SearchIcon } from "./SvgIcons";

const SearchBar = ({ onSearch, placeholer, noMargin }) => {
  return (
    <InputGroup className={`${noMargin ? "mb-0" : "search-group mb-3"} ${"search-group"}`}>
      <InputGroup.Text id="basic-addon1">
        <SearchIcon />
      </InputGroup.Text>
      <FormControl
        placeholder={placeholer ? placeholer : "Search"}
        aria-label="Search"
        aria-describedby="Search"
        maxLength={"255"}
        onChange={(e) => onSearch(e.target.value)}
      />
    </InputGroup>
  );
};

export default SearchBar;
