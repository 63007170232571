import { api, axiosService } from "./api.service";
import {BASE_URL, S3_BASE_URL, constants, MOB_BASE_URL} from "../utils/constants";

export const SetBaseUrl = () => {
  const storageUser = localStorage.getItem(constants.LOCAL_STORAGE_USER);
  let base_url = BASE_URL;
  /*if (storageUser !== null) {
        let user = JSON.parse(storageUser)?.user;
        if (user && user.roles) {
            let role_id = user.roles[0].id;
            if (role_id == constants.ROLES.ROLE_ADMIN) {
                base_url = constants.ADMIN_URL;
            } else if (role_id == constants.ROLES.ROLE_VENDOR) {
                base_url = constants.VENDOR_URL;
            } else if (role_id == constants.ROLES.ROLE_CSA) {
                base_url = constants.CSA_URL;
            } else {
                base_url = constants.BASE_URL;
            }
        }
    }*/
  return base_url;
};

export const SetMobBaseUrl = () => {
  let base_url = MOB_BASE_URL;
  return base_url;
};

export const SetS3BaseUrl = () => {
  let base_url = S3_BASE_URL;
  return base_url;
};

// export const POST = async (url, data = null, config = null) => {
//     var res = await api.post(SetBaseUrl() + url, data, config)

//     return res?.data
// }

export const POST = async (url, data = null, config = {}) => {
  // Set the base URL
  const baseURL = SetBaseUrl();

  // Set default headers or use the existing ones from the config object
  config.headers = {
    "Content-Type": "application/json", // Adjust the content type as needed
    // Add any other headers as needed
    ...config.headers,
  };

  // Set withCredentials to true for CORS with credentials
  //config.withCredentials = true;

  try {
    // Make the POST request
    const res = await api.post(baseURL + url, data, config);

    return res?.data;
  } catch (error) {
    // Handle errors here
    console.error("POST request failed:", error);
    throw error;
  }
};

export const Post_Without_Token = (url, data = null) => {
  return axiosService.post(SetBaseUrl() + url, data);
};

export const GET = async (url, params = null) => {
  var res = await api.get(SetBaseUrl() + url, {
    params,
  });
  return res?.data;
};

export const PUT = async (url, id, data = null, config = null) => {
  var res = await api.put(SetBaseUrl() + url + "/" + id, data, config);
  return res?.data;
};

export const DELETE = async (url, id) => {
  var res = await api.delete(SetBaseUrl() + url + "/" + id);
  return res?.data;
};

export const DELETEMOB = async (url, id,data ,config ={}) => {
  // Set default headers or use the existing ones from the config object
  console.log(data,"data")
  config.headers = {
    "Content-Type": "application/json",
    data:data,// Adjust the content type as needed
    // Add any other headers as needed
    ...config.headers,
  };

  var res = await api.delete(SetMobBaseUrl() + url + "/" + id, config);
  return res?.data;
};

export const PUTMOB = async (url, id, data = null, config = null) => {
  var res = await api.put(SetMobBaseUrl() + url + "/" + id, data, config);
  return res?.data;
};
export const PATCH = async (url, id) => {
  var res = await api.patch(SetBaseUrl() + url + "/" + id);
  return res?.data;
};

export const UPDATE = async (url, id, data) => {
  var res = await api.put(SetBaseUrl() + url + "/" + id, data);
  return res?.data;
};
