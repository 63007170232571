import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import { FadeLoader } from "react-spinners";
import { AddTimeZoneService } from "../../services/timezone.service";
import { AddFaqsService } from "../../services/faq.api.service";

function AddFaqs() {
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [cities, setCities] = useState([""]);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  const onSubmit = async (data) => {

    setLoader(true);
    const dt = {
      question: data.question,
      answer: data.description,
    };
    await AddFaqsService(dt)
      .then(async (data) => {
        setLoader(false);
        if (data.success) {
          toast.success("FAQ created successfully");
          history.push("/faqs");
        } else {
          setLoader(false);

          toast.error(data.message);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          console.log(error);
          return toast.error(error.response.data.message);
        }
      });
  };

  const handleAddCity = () => {
    setCities([...cities, ""]);
  };

  const handleDeleteCity = (index) => {
    const updatedCities = [...cities];
    updatedCities.splice(index, 1);
    setCities(updatedCities);
    setValue("cities", updatedCities); // Update form state
  };

  const handleCityChange = (index, value) => {
    const updatedCities = [...cities];
    updatedCities[index] = value;
    setCities(updatedCities);
    setValue("cities", updatedCities); // Update form state
  };

  return (
    <div className="AddNewUser AddTimeZone">
      <h3 className="page-heading">Add FAQ</h3>
      <Row>
        <Col xs={12} sm={12} md={8} lg={6} xl={7}>
          <Form
            encType="multipart/form-data"
            className="formAddCatrgories"
            autoComplete="off"
            role="presentation"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Form.Group className="mb-3" controlId="question">
                  <Form.Label>
                    Question<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    placeholder="Enter Question"
                    {...register("question", {
                      required: "Question is required",
                      pattern:{
                        value:/^(?!\s).*$/,
                        message:"Question should not start with a space"
                      },
                      minLength: { value: 3, message: "Min length is 3" },
                      maxLength: { value: 150, message: "Max length is 150" },
                    })}
                    type="text"
                  />
                  {errors.question && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.question.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Form.Group controlId="description" className="mb-3">
                  <Form.Label>Description<span className="text-danger">*</span></Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    placeholder="Enter Description"
                    {...register("description", {
                      required: {
                        value: "required",
                        message: "Description is required",
                      },
                      pattern:{
                        value:/^(?!\s).*$/,
                        message:"Description should not start with a space"
                      },
                      minLength: {
                        value: 3,
                        message: "Min length is 3",
                      },
                      maxLength: {
                        value: 450,
                        message: "Max length is 450",
                      },
                    })}
                  />
                  {errors.description && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.description.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                {loader ? (
                  <div className="spin-loader">
                    <FadeLoader color={"#8b4dc6"} height={10} />
                  </div>
                ) : (
                  <input
                    type={"submit"}
                    className={"btn btn-green-theme w-100 mt-3"}
                    value={"Add FAQ"}
                  />
                )}
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default AddFaqs;
