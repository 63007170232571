import React, { useState, useEffect, useCallback } from "react";
import { Button, Col, Dropdown, DropdownButton, Modal, Row } from 'react-bootstrap';
import SearchBar from '../../components/SearchBar';
import debounce from "lodash.debounce";
import { Link, useHistory } from "react-router-dom";
import swal from "sweetalert";
import { DeletePackages, GetAllPackages } from "../../services/packages.service";
import { toast } from "react-toastify";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import { MenuDots } from "../../components/SvgIcons";
import DataTable from "react-data-table-component";
import { customStyles } from "../../utils/constants";
import { BarLoader } from "react-spinners";

const PackageTableComponent = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [setsubscribeUserData, setSetsubscribeUserData] = useState([])
    const [totalSubscribeRows, setTotalSubscribeRows] = useState([]);
    const [totalRows, setTotalRows] = useState(10);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [showModal, setShowModal] = useState(false);
    const [citiesList, setCitiesList] = useState([]);

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    const init = useCallback(
        async (searchTerm) => {
            try {
                const packageParams = {
                    keyword: searchTerm,
                    page,
                    perPage,
                    is_paginated: 1,

                };
                setLoading(true)
                const result = await GetAllPackages(packageParams);
                if (result.status) {

                    setTotalRows(result.data.packages?.meta?.total);

                    const rowData = result.data.packages?.data?.map((dt, index) => ({
                        id: dt.id || "-",
                        name: dt.name || "-",
                        price: dt.price || "-",
                        description: dt.description || "-",
                        cities: dt.cities_list || [],
                        best_value: dt.best_value || [],
                        identifiers: dt.identifiers || [],
                    }));

                    setRows(rowData);
                    setLoading(false);
                    // Extract cities list from the first row (assuming all rows have the same cities)
                    if (rowData.length > 0) {
                        setCitiesList(rowData[0]?.cities || []);
                    }
                } else {
                    setLoading(false);
                    toast.error(result.message);
                }
            } catch (error) {
                console.error("Error:", error);

                setLoading(false);

                if (error.response && error.response.status === 401) {
                    EmptyLocalStorage();
                    history.push("/");
                } else {
                    toast.error(
                        error.response ? error.response.data.message : "An error occurred"
                    );
                }
            }
        },
        [history, page, perPage]
    );

    const onDelete = (id) => {
        swal({
            title: "Are you sure?",
            text: "Are you sure you want to delete this package?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willShip) => {
            if (willShip) {
                await DeletePackages(id)
                    .then((result) => {
                        if (result.status) {
                            swal(result.message, {
                                icon: "success",
                            });
                            init();
                        } else {
                            toast.error(result.message);
                        }
                    })
                    .catch((error) => {
                        if (error.response && error.response.status === 401) {
                            EmptyLocalStorage();
                            history.push("/");
                        } else {
                            return toast.error(
                                error.response
                                    ? error.response.data.message
                                    : "An error occurred"
                            );
                        }
                    });
            }
        });
    };

    const onEdit = (data) => {
        history.push(`/packages-and-subscription/edit-package/${data.id}`, {
            data: data,
        });
    };

    const userActions = (data) => (
        <DropdownButton
            align="end"
            className={"actionDropdown"}
            id="dropdown-item-button"
            title={<MenuDots />}
        >
            <Dropdown.Item as="button" onClick={() => onEdit(data)}>
                Edit
            </Dropdown.Item>
            <Dropdown.Item as="button" onClick={() => onDelete(data.id)}>
                Delete
            </Dropdown.Item>
        </DropdownButton>
    );

    const ListOfCities = (rowData) => {
        const data = rowData.description || [];
        const truncatedData = data.split(' ').slice(0, 3).join(' ') + (data.split(' ').length > 3 ? ' ...' : '');

        const handleShowModalForCities = () => {
            setCitiesList(rowData?.description || []);
            handleShowModal();
        };

        return (
            <div className="d-flex align-items-center flex-wrap cursor-pointer" onClick={handleShowModalForCities}>
                <p className="m-0">{truncatedData}</p>
            </div>
        );
    };
    // ================== Tables Columns ====================
    const columns = [
        {
            name: "S.No",
            maxWidth: "60px",
            center: true,
            cell: (row, index) => <span>{index + 1}</span>,
        },
        {
            name: "Name",
            selector: "name",
            maxWidth: "200px",
            center: false,
            sortable: true,
        },
        {
            name: "price",
            selector: "price",
            maxWidth: "200px",
            center: true,
            sortable: true,
            cell: (rowData) => `$${rowData.price}`,
        },
        {
            name: "description",
            selector: "description",
            maxWidth: "300px",
            sortable: true,
            center: true,
            cell: (rowData) => <ListOfCities {...rowData} />,
        },
        // {
        //     name: "Best Value",
        //     selector: "best_value",
        //     maxWidth: "200px",
        //     center: true,
        //     sortable: true,
        // },
        // {
        //     name: "Identifiers",
        //     selector: "identifiers",
        //     maxWidth: "200px",
        //     center: true,
        //     sortable: true,
        // },
        {
            name: "Action",
            center: true,
            cell: (rowData) => userActions(rowData),
        },
    ];
    //Debounce Seatch Input
    const debouncedRequest = debounce((searchTerm) => init(searchTerm), 500);
    useEffect(() => {
        init();
    }, [page, perPage]);
    return (
        <div className="packageTableComponent mb-3">

            <div className="theme-dataGrid products-dataGrid">
                <Row>
                    <Col xs={12} sm={12} md={6} lg={6} xl={6}> <h4>Packages</h4></Col>
                    <Col xs={12} sm={12} md={6} lg={6} xl={6} className="d-flex justify-content-end">
                        <SearchBar onSearch={(value) => debouncedRequest(value)} />
                    </Col>


                </Row>
                <div className="mt-3">
                    <DataTable
                        columns={columns}
                        data={rows}
                        progressPending={loading}
                        customStyles={customStyles}
                        selectableRows={false}
                        striped
                        highlightOnHover
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        paginationPerPage={perPage}
                        onChangePage={(page) => {
                            setPage(page);
                        }}
                        onChangeRowsPerPage={(currentRowsPerPage) => {
                            setPerPage(currentRowsPerPage);
                        }}
                        progressComponent={
                            <BarLoader
                                color={"#ff00e6"}
                                loading={loading}
                                css={"marginTop: 10px"}
                                height={"4"}
                                width={"100%"}
                            />
                        }
                    />
                    <Modal show={showModal} onHide={handleCloseModal} centered>
                        <Modal.Header closeButton>
                            <h4 className="m-0">Description</h4>
                        </Modal.Header>
                        <Modal.Body>{citiesList}</Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="secondary"
                                className={"btn btn-green-theme h40 w-100"}
                                onClick={handleCloseModal}
                            >
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
        </div>
    )
}

export default PackageTableComponent