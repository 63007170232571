import { apiUrl } from "../utils/constants";
import { DELETE, GET, PATCH, POST, PUT, UPDATE } from "./api.service.wrapper";

export const GetUserReports = async (params) => {
  const response = await GET(apiUrl.user_reports, params);
  return response;
};

export const GetBusinessReports = async (params) => {
  const response = await GET(apiUrl.business_reports, params);
  return response;
};

export const GetReports = async (params) => {
  const response = await GET(apiUrl.reports, params);
  return response;
};



export const GetAllReports = async () => {
  const response = await GET(apiUrl.reports);
  return response;
};

export const AddReportservice = async (data) => {
  const response = await POST(apiUrl.reports, data);
  return response;
};

export const UpdateReportsService = async (id, data) => {
  const response = await UPDATE(apiUrl.reports, id, data);
  return response;
};

export const DeleteReports = async (id) => {
  const response = await DELETE(apiUrl.reports, id);
  return response;
};

