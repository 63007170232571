import { apiUrl } from "../utils/constants";
import { DELETE, GET, PATCH, POST, PUT, UPDATE } from "./api.service.wrapper";

export const GetFaqs = async (params) => {
  const response = await GET(apiUrl.faqs + '/get', params);
  return response;
};

export const AddFaqsService = async (data) => {
  const response = await POST(apiUrl.faqs + '/create', data);
  return response;
};

export const DeleteFaq = async (id) => {
  const response = await DELETE(apiUrl.faqs, id);
  return response;
};

export const UpdateFaqsById = async (id, data) => {
  const response = await UPDATE(apiUrl.faqs, id, data);
  return response;
};
