import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Form, FormControl, InputGroup, Row } from 'react-bootstrap'
import { useForm } from "react-hook-form";
import { useHistory } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import { toast } from "react-toastify";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import { FadeLoader } from "react-spinners";
import {GetUserById, UpdateUser} from "../../services/users.service";
import {uploadToS3} from "../../utils/helpers";



function EditUser(props) {
    let history = useHistory();
    const [data, setData] = useState([]);
    const [pass, TogglePass] = useState(true);
    const [image, setImage] = useState()
    const [isUpload, setIsUpload] = useState(false)
    const [isUploadOverWrite, setIsUploadOverWrite] = useState(false)
    const [file, setFile] = useState(null)
    const [confPass, ToggleConfPass] = useState(true);
    const [confirmPass, setConfirmPass] = useState(true);
    const [age, setAge] = useState({ format: "mm/dd/yyyy" })
    const [loader, setLoader] = useState(false);
    const [idImage, setIdImage] = useState()
    const [fileId, setFileId] = useState(null)
    const [isUploadId, setIsUploadId] = useState(false)
    const { register, watch, setError, clearErrors,setValue, handleSubmit, formState: { errors } } = useForm({
        mode: "onBlur",
    });
    const regex= new RegExp("^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$")
    const maxDate = new Date().toISOString().split('T')[0]; // Get today's date in ISO format
    useEffect(async () => {
        let id = props.match.params.id;
        await getUserByid(id);
    }, [])

    const getUserByid = async (id) => {
        await GetUserById(id).then((result) => {
            if (result.success) {
                if (result.data) {
                    setData(result.data.user);
                    setValue("username", result.data.user?.username);
                    setValue("email", result.data.user?.email);
                    if(result.data.user?.profileImage){
                        setImage(result.data.user?.profileImage)
                        setIsUpload(true)
                    }
                    if(result.data.user?.profileId){
                        setIdImage(result.data.user?.profileId)
                        setIsUploadId(true)
                    }
                }
            } else {
                return toast.error(result.message);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    }


    const password = useRef({});
    password.current = watch("password", "");

    const onSubmit = async data => {
        if(!image){
            setError('media' ,{message:"Media is required"})
            return false;
        }
        if(isUploadOverWrite){
        if(file.type.startsWith('image/')){
            clearErrors('media')
        }else{
            setError('media',{message:'Invalid file type'})
            return false;
        }}
        setLoader(true)
        if(isUploadOverWrite){
            if (!file){
                setError('media' ,{message:"Media is required"})
            }

            let uploadImage = await uploadToS3(file.type,file.name,file,'user');

            if (uploadImage) {
                if (!uploadImage) {
                    setLoader(false)
                    toast.error(uploadImage.message);
                    return false;
                }
            } else {
                setLoader(false)
                toast.error('Can\'t Upload Image');
                return false;
            }
            data.media=uploadImage;
        }else {
            data.media = image
        }

        let uploadIdImage;
        if(fileId){
            if(fileId?.type.startsWith('image/')){
                clearErrors('profileId')
            }else{
                setError('profileId',{message:'Invalid file type'})
                return false;
            }
            setLoader(true)
            uploadIdImage = await uploadToS3(fileId.type,fileId.name,fileId,'category');
        }
        data.profileId=uploadIdImage ? uploadIdImage : idImage;
        let id = props.match.params.id;
        let dt = {
            username: data.username,
            profileImage: data.media,
            profileId: data.profileId,
        }
        if (data.password && data.password_confirmation) {
            dt.password = data.password;
        }
        await UpdateUser(id, dt).then(async (data) => {
            setLoader(false)
            if(!data.error){
                toast.success(data.msg);
                history.push('/users');
            } else{
                setLoader(false)
                toast.error(data.msg);
            }
        }).catch((error) => {
            setLoader(false);
            if (error.response.status === 401) {
                EmptyLocalStorage();
                history.push('/');
            } else {
                if (error.response.data.status === false && error.response.data.message && error.response.data.data && Array.isArray(error.response.data.data)) {
                    const errorMessages = error.response.data.data.map((message) => message).join(", ");
                    toast.error(`${error.response.data.message}: ${errorMessages}`);
                } else {
                    toast.error(error.response.data.message);
                }
            }
        })


    };

    const onAgeChange = (e) => {
        const object = e.target.value;
        const date = new Date(object);
        setAge(date.toLocaleDateString())
    }

    const uploadFile = async (fileInput) => {

        let fileUrl = URL.createObjectURL(fileInput[0])
        setImage(fileUrl)
        setFile(fileInput[0])
        setIsUpload(true)
        if(fileUrl){
            setIsUploadOverWrite(true)
        }

    }

    const validateMedia = (fileList) => {
        const file = fileList[0];
        if (file && file.size > 5 * 1024 * 1024) {
            return 'Image size should be less than 5MB';
        }
        return true; // Validation passed
    };

    const uploadIDFile = async (fileInput) => {

        let fileUrl = URL.createObjectURL(fileInput[0])
        setIdImage(fileUrl)
        setFileId(fileInput[0])
        setIsUploadId(true)
        const fileType = fileInput[0]?.type;

    }
    const validateIdMedia = (fileList) => {
        const file = fileList[0];
        if (file && file.size > 5 * 1024 * 1024) {
            return 'Image size should be less than 5MB';
        }
        return true; // Validation passed
    };

    return (
        <div className="EditUser">
            {/*<BreadcrumbsComponent />*/}
            <h3 className="page-heading">Edit User</h3>
            <Row>
                <Col xs={12} sm={12} md={10} lg={12} xl={6}>
                    <Form encType="multipart/form-data" className="formAddUser" autoComplete="off" role="presentation" onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="image">
                                    <Form.Label>Image* <Form.Text className=" text-muted" style={{fontSize:".675em"}}>(Max upload file size: 5 MB)</Form.Text> </Form.Label>
                                    <input
                                        {...register('media',{
                                            validate: validateMedia // Use the custom validation function
                                        })}
                                        onChange={(e) => uploadFile(e.target.files)}
                                        type='file'
                                        accept={'.jepg,.jpg,.png'}
                                        class="custom-file-input"
                                        id='image'
                                    />

                                    <br/>
                                    {errors.media && <Form.Text
                                        className=" validationText hasError">{errors.media.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                        </Row>
                        {isUpload ?
                            <Row>
                                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <ul className={"list-inline list-variations"}>
                                        <li className={"list-inline-item d-flex justify-content-between mb-0"}>
                                            <img  src={image} width="50%" height="50%"/>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                            :  ""
                        }
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="image">
                                    <Form.Label>Upload ID <Form.Text className=" text-muted" style={{fontSize:".675em"}}>(Max upload file size: 5 MB)</Form.Text> </Form.Label>
                                    <input
                                        {...register('profileId',{
                                            validate: validateIdMedia // Use the custom validation function
                                        })}
                                        onChange={(e) => uploadIDFile(e.target.files)}
                                        type='file'
                                        accept={'.jepg,.jpg,.png'}
                                        class="custom-file-input"
                                        id='image'
                                    />

                                    <br/>
                                    {errors.profileId && <Form.Text
                                        className=" validationText hasError">{errors.profileId.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                        </Row>
                        {isUploadId ?
                            <Row>
                                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <ul className={"list-inline list-variations"}>
                                        <li className={"list-inline-item d-flex justify-content-between mb-0"}>
                                            <img  src={idImage} width="50%" height="50%"/>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                            :  ""
                        }
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="full_name">
                                    <Form.Label>Full Name*</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Full Name"
                                        id={"formSignUpName"}
                                        maxLength={25}
                                        minLength={3}
                                        {...register('username', {
                                            required: {
                                                value: "required",
                                                message: "Full Name is required"
                                            },
                                            pattern:{
                                                value:/^(?!\s).*$/,
                                                message:"Full Name should not start with a space"
                                            },
                                            minLength: {
                                                value: 2,
                                                message: "Min length is 2"
                                            },
                                            maxLength: {
                                                value: 100,
                                                message: "Max length is 100"
                                            },
                                        })} type="text"
                                    />
                                    {errors.username && <Form.Text
                                        className="text-muted validationText hasError">{errors.username.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="email">
                                    <Form.Label>Email*</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Email Address"
                                        autocomplete="off"
                                        autofill="off"
                                        maxLength={255}
                                        {...register('email', {
                                            required: {
                                                value: "required",
                                                message: "Email is required"
                                            },
                                            pattern: {
                                                value: /\S+@\S+\.\S+/,
                                                message: "Entered value does not match email format"
                                            },
                                            maxLength: {
                                                value: 100,
                                                message: "Max length is 100"
                                            },
                                        })} type="email" readOnly/>
                                    {errors.email && <Form.Text
                                        className="text-muted validationText hasError">{errors.email.message}</Form.Text>}
                                </Form.Group>
                            </Col>

                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <div className={"pass-field mb-3"}>
                                    <Form.Label>Password</Form.Label>
                                    <InputGroup controlId="passowrd">
                                        <FormControl
                                            placeholder="Enter Password"
                                            autoComplete={"off"}
                                            maxLength={255}
                                            minLength={8}
                                            {...register('password', {
                                                pattern: {
                                                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                                                    message: 'Password should contain a mix of uppercase and lowercase letters, numbers, and special characters'
                                                },
                                                minLength: {
                                                    value: 8,
                                                    message: "Min length is 8"
                                                },
                                                maxLength: {
                                                    value: 255,
                                                    message: "Max length is 255"
                                                }
                                            })}
                                            type={pass ? "password" : "text"} />
                                        <Button variant="outline-secondary" id="button-addon2" onClick={() => TogglePass(!pass)}>
                                            {
                                                pass ? <FaEye /> : <FaEyeSlash />
                                            }
                                        </Button>
                                    </InputGroup>
                                    {errors.password && <Form.Text className="text-muted validationText hasError">{errors.password.message}</Form.Text>}
                                </div>
                            </Col>

                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <div className={"pass-field mb-3"}>
                                    <Form.Label>Confirm Password</Form.Label>
                                    <InputGroup controlId="password_confirmation">
                                        <Form.Control
                                            placeholder="Enter Password Again"
                                            maxLength={25}
                                            minLength={3}
                                            {...register('password_confirmation', {
                                                minLength: {
                                                    value: 8,
                                                    message: "Min length is 8"
                                                },
                                                validate: (value) => {
                                                    if ( password.current?.length > 0 && !value) {
                                                        return "The confirm password is required";
                                                    } else if (password.current && password.current?.length > 0) {
                                                        return value === password.current || "The passwords do not match";
                                                    }
                                                    return true;
                                                },
                                                maxLength: {
                                                    value: 255,
                                                    message: "Max length is 255"
                                                }
                                            })}
                                            type={confPass ? "password" : "text"}
                                        />
                                        <Button variant="outline-secondary" id="button-addon2" onClick={() => ToggleConfPass(!confPass)}>
                                            {
                                                confPass ? <FaEye /> : <FaEyeSlash />
                                            }
                                        </Button>
                                    </InputGroup>
                                    {errors.password_confirmation && <Form.Text className="text-muted validationText hasError">{errors.password_confirmation.message}</Form.Text>}
                                </div>
                            </Col>


                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                {loader ? <div className="spin-loader">
                                        <FadeLoader color={"rgb(139, 77, 198)"} height={10} />
                                    </div> :
                                    <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"}
                                           value={"Update User"} />}
                            </Col>


                        </Row>
                    </Form>

                </Col>
            </Row>

        </div>
    )
}

export default EditUser
