import { apiUrl } from '../utils/constants';
import {api} from "./api.service";

 const POST = async (url, data = null, config = {}) => {
    // Set the base URL
    const baseURL = `${process.env.REACT_APP_BASE_URL_MOBILE}`;

    // Set default headers or use the existing ones from the config object
    config.headers = {
        "Content-Type": "application/json", // Adjust the content type as needed
        // Add any other headers as needed
        ...config.headers,
    };

    // Set withCredentials to true for CORS with credentials
    //config.withCredentials = true;

    try {
        // Make the POST request
        const res = await api.post(baseURL + url, data, config);

        return res?.data;
    } catch (error) {
        // Handle errors here
        console.error("POST request failed:", error);
        throw error;
    }
}

export const SendNotification = async (data) => {
    let response = '';
    response = await POST(apiUrl.notification, data);
    return response;
}