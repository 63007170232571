import React, { useEffect, useState } from 'react';
import {useHistory, useLocation} from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import { Col, Form, Row } from "react-bootstrap";
import { FadeLoader } from "react-spinners";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { GetPageById, UpdatePage } from "../../services/pages.service";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

function EditPage(props) {
    let history = useHistory();
    const [data, setData] = useState([]);
    const [formData, setFormData] = useState({});
    const [loader, setLoader] = useState(false);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const { register, setValue, handleSubmit, setError, clearErrors, formState: { errors } } = useForm({
        mode: "onBlur",
    });
    const location = useLocation();

    useEffect(async () => {
        let id = props.match.params.id;
        await getPageByid(id);
        // if (data) {
        //     reset({
        //         'full_name': data.full_name,
        //         // 'username': data.username,
        //         'email': data.email,
        //         'phone': data.phone,
        //         'dob': data.dob,
        //         //'company_name': data.company_name,
        //         //'business_type': data.business_type,
        //     });
        //
        //
        // }
    }, []);
    const getPageByid = async (id) => {
        const { data } = location.state;
                    setValue("slug", data?.slug);
                    setValue("title", data?.title);

                    // Extract content from the nested structure
                    const content = data?.content || '';

                    // Convert HTML to DraftJS content
                    const contentState = ContentState.createFromBlockArray(htmlToDraft(content).contentBlocks);

                    // Create EditorState with the converted content
                    const newEditorState = EditorState.createWithContent(contentState);

                    // Set the editor state
                    setEditorState(newEditorState);

    }
    const onSubmit = async data => {
        setLoader(true)
        let dt = {
            [`${data.title}Cms`]: draftToHtml(convertToRaw(editorState.getCurrentContent())),
        };

        await UpdatePage(null, dt,data.slug).then(async (data) => {
            setLoader(false)
            if (data.success) {
                toast.success(data.message);
                history.push('/pages');
            } else {
                setLoader(false)
                // setDisableButton(false)
                toast.error(data.message);
            }
        }).catch((error) => {
            setLoader(false)
            // setDisableButton(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })


    };

    const onEditorStateChange = (newEditorState) => {
        setEditorState(newEditorState)
    }

    return (
        <div className="EditPage">
            {/*<BreadcrumbsComponent />*/}
            <h3 className="page-heading">Edit Page</h3>
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Form className="formAddUser" autoComplete="off" role="presentation"
                        onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="slug">
                                    <Form.Label>Slug*</Form.Label>
                                    <Form.Control
                                        readOnly
                                        {...register('slug')} type="text"
                                    />
                                    {errors.slug && <Form.Text
                                        className=" validationText hasError">{errors.slug.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="slug">
                                    <Form.Label>Title*</Form.Label>
                                    <Form.Control
                                        readOnly
                                        {...register('title')} type="text"
                                    />
                                    {errors.title && <Form.Text
                                        className=" validationText hasError">{errors.title.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mb-3" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Editor
                                    editorState={editorState}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                    onEditorStateChange={onEditorStateChange}
                                    toolbar={{
                                        options: ['blockType', 'list', 'textAlign', 'history'],
                                        blockType: {
                                            inDropdown: true,
                                            options: ['H4'],
                                            className: undefined,
                                            component: undefined,
                                            dropdownClassName: undefined,
                                        }
                                    }}
                                />
                            </Col>
                        </Row>


                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                {loader ? <div className="spin-loader">
                                    <FadeLoader color={"rgb(139, 77, 198)"} height={10} />
                                </div> :
                                    <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"}
                                        value={"Update Page"} />}
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>

        </div>
    )
}

export default EditPage;