import React, {useEffect, useRef, useState} from 'react'
import swal from "sweetalert"
import {useHistory} from "react-router-dom"
import {Button, Col, Dropdown, DropdownButton, Form, FormControl, InputGroup, Row} from "react-bootstrap"
import {MenuDots, SearchIcon} from "../../components/SvgIcons"
import {toast} from "react-toastify";
import DataTable, {createTheme} from 'react-data-table-component';
import BarLoader from "react-spinners/BarLoader";
import {constants, customStyles} from "../../utils/constants";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {DeleteBusiness, GetBusiness, UpdateBusinessStatus} from "../../services/business.service";
import Select from "react-select";
import {colourStyles} from "../../utils/helpers";

function Business() {
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(constants.PERPAGE);
    const [rows, setRows] = useState([{
        id:1,
        bussinessName: 'bebark',
        bussinessStatus:'approved',
        bussinessCategory:'club'
    }]);
    const [keyword, setKeyword] = useState('');
    const [gender, setGender] = useState('');
    const [type, setType] = useState('');
    const [value, setValue] = useState('');
    const [email, setEmail] = useState(false);
    const typingTimeoutRef = useRef(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const [userStatus, setUserStatus] = useState({label:"Filter Status",value:null});
    const [category, setCategory] = useState({label:"Filter Category",value:null});
    const [searchBy, setSearchBy] = useState({label:"Search By",value: null});
    const [searchoption, setSearchOption] = useState([
        { value: "userName", label: "User Name" },
        { value: "bussinessName", label: "Business Name" },
    ]);

    const [statusOption, setStatusOption] = useState([
        { value: "approved", label: "Approved" },
        { value: "pending", label: "Pending" },
        { value: "rejected", label: "Rejected" },
    ]);

    const [categoryOption, setCategoryOption] = useState([
        { value: "club", label: "Club" },
        { value: "bar", label: "Bar" },
    ]);

    createTheme('solarized', {
        text: {
            primary: '#ffffff',
            secondary: '#929399',
        },
        background: {
            default: '#0d0f0f',
        },
        context: {
            background: '#0d0f0f',
            text: '#929399',
        },
        highlightOnHover: {
            default: 'rgb(139 77 198)',
            text: '#ffffff',
        },
        divider: {
            default: '#929399',
        },
        action: {
            button: 'rgba(0,0,0,.54)',
            hover: 'rgba(0,0,0,.08)',
            disabled: 'rgba(0,0,0,.12)',
        },
    }, 'dark');

    useEffect(async () => {
        await getBusiness();
    }, [keyword, page, perPage,category,userStatus])

    const getBusiness = async () => {
        setLoading(true)
        await GetBusiness(keyword, page, perPage,0,userStatus?.value,category?.value,searchBy?.value).then((result) => {
            if (result?.success) {
                if (result?.data) {
                    const rowData = [];
                    setPage(result?.data?.pagination?.page)
                    setTotalRows(result.data.pagination.total);
                    let page = result.data.pagination.page-1
                    let counter = (page*result.data.pagination.limit)+1;
                    result.data.business.map((dt, index) => {
                        rowData.push({
                            sno: counter++,
                            id:dt._id || '-',
                            userName: dt?.user?.username || '-',
                            userEmail: dt?.user?.email || '-',
                            bussinessName: dt.bussinessName || '-',
                            bussinessStatus: dt.bussinessStatus || '-',
                            bussinessCategory: dt.bussinessCategory || '-',
                        });

                    })
                    setRows(rowData);
                    setLoading(false)
                }
            } else {
                setLoading(false)
                setRows([]);
            }
        }).catch((error) => {
            setLoading(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else if(error.response.status == 404) {
                setRows([]);
            }else {
                return toast.error(error.response.data.message);
            }
        })
    }





    const onBusinessDelete = (id) => {
        swal({
            title: "Are you sure?",
            text: "Are you sure you want to delete this Business?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willShip) => {
            //Delete  API
            if (willShip) {
                await DeleteBusiness(id).then((result) => {
                    if (!result.error) {
                        swal(result.msg, {
                            icon: "success",
                        });
                        getBusiness();
                    } else {
                        toast.error(result.message);
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        EmptyLocalStorage()
                        history.push('/');
                    } else {
                        return toast.error(error.response.data.message);
                    }
                })
            }
        });
    }

    const onEdit = (data) => {
        history.push('/businesses/edit-business/' + data.id, {
            data: data
        });
    }

    const onView = (data) => {
        history.push('/businesses/preview/' + data.id, {
            data: data
        });
    }

    const onApprove = async (data,status) => {
        swal({
            title: "Are you sure?",
            text: `Are you sure you want to ${status === "approved" ? 'approve' : 'reject'} this business?`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willShip) => {
            //Delete  API
            if (willShip) {
                await UpdateBusinessStatus( {
                    partnerId:data.id,
                    newStatus:status}).then((result) => {
                    if (result.success) {
                        swal(result.message, {
                            icon: "success",
                        });
                        getBusiness();
                    } else {
                        toast.error(result.msg);
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        EmptyLocalStorage()
                        history.push('/');
                    } else {
                        return toast.error(error.response.data.message);
                    }
                })
            }
        });
    }
    const userActions = (data) => {
        return (
            <DropdownButton align="end" className={"actionDropdown"} id="dropdown-item-button" title={<MenuDots />}>
                <Dropdown.Item as="button" onClick={() => onEdit(data)}>Edit</Dropdown.Item>
                <Dropdown.Item as="button" onClick={() => onView(data)}>View</Dropdown.Item>
                {data?.bussinessStatus === 'pending' &&  <Dropdown.Item as="button" onClick={() => onApprove(data,'approved')}>Approve</Dropdown.Item>}
                {data?.bussinessStatus === 'pending' &&  <Dropdown.Item as="button" onClick={() => onApprove(data,'rejected')}>Reject</Dropdown.Item>}
                <Dropdown.Item as="button" onClick={() => onBusinessDelete(data.id)}>Delete</Dropdown.Item>
            </DropdownButton>
        )
    }

    const handleImageClick = (imageSrc) => {
        setSelectedImage(imageSrc);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const columns = [
        {
            id: 'id',
            name: 'Id',
            sortable: true,
            selector: rowData => rowData.sno,
        },
        // {
        //     id: 'image',
        //     name: 'Image',
        //     maxWidth: '100px',
        //     selector: rowData => rowData.image,
        //     format: rowData => <img src={rowData.image}
        //                             onClick={() => handleImageClick(rowData.image)}
        //                             onError={({ currentTarget }) => {
        //                                 currentTarget.onerror = null;
        //                                 currentTarget.src = constants.IMAGENOTFOUND;
        //                             }}
        //                             alt={'Broken'}
        //                             className={"img-table img-thumbnail"} crossOrigin={"anonymous"} />
        // },
        {
            id: 'userName',
            name: 'User Name',
            sortable: true,
            selector: rowData => rowData.userName,
        },
        {
            id: 'userEmail',
            name: 'User Email',
            minWidth:'200px',
            sortable: true,
            selector: rowData => rowData.userEmail,
        },
        {
            id: 'bussinessName',
            name: 'Business Name',
            sortable: true,
            selector: rowData => rowData.bussinessName,
        },
        {
            id: 'businessStatus',
            name: 'Business Status',
            minWidth:'150px',
            sortable: true,
            grow: false,
            center: true,
            selector: rowData => rowData.bussinessStatus,
        },
        {
            id: 'businessCategory',
            name: 'Business Category',
            minWidth:'150px',
            sortable: true,
            grow: false,
            center: true,
            selector: rowData => rowData.bussinessCategory,
        },
        {
            id: 'action',
            name: 'Action',
            center: true,
            cell: rowData => userActions(rowData)
        },
    ];



    const handleKeyUp = (e) => {
        clearTimeout(typingTimeoutRef.current);

        const value = e.target.value;
        if (value.length > 2) {
            typingTimeoutRef.current = setTimeout(() => {
                setKeyword(value);
            }, 500); // Set keyword after 500 milliseconds of inactivity
        } else {
            setKeyword(null);
        }
    };

    const optionsStatus = (selectedOptions) => {
        console.log("selectedOptions:",selectedOptions)
        setUserStatus(selectedOptions || {});
    }

    const optionsCategory = (selectedOptions) => {
        setCategory(selectedOptions || {});
    }

    const optionsSearch = (selectedOptions) => {
        setSearchBy(selectedOptions || {});
    }
    return (
        <div className={"usersPage"}>
            {/*<h1 className="page-heading">Business ({totalRows})</h1>*/}
            <h1 className="page-heading">Businesses</h1>
            <div className={"customOrderFilters"}>
                <Row>
                    <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                        <InputGroup className="mb-3 search-group">
                            <InputGroup.Text id="basic-addon1"><SearchIcon /></InputGroup.Text>
                            <FormControl
                                placeholder="Search"
                                aria-label="Search"
                                aria-describedby="Search"
                                maxLength={"255"}
                                onKeyUp={handleKeyUp}
                            />
                        </InputGroup>
                    </Col>

                    <Col xs={12} sm={12} md={{span:2 ,offset:2}} lg={{span:2 ,offset:2}} xl={{span:2 ,offset:2}}>

                    </Col>
                    {/*<Col xs={12} sm={12} md={3} lg={3} xl={3}>*/}
                    {/*    <Link to={"/businesses/add-business"}>*/}
                    {/*        <Button className={"btn btn-green-theme h40 w-100 mb-md-0 mb-sm-3 mb-3"}>*/}
                    {/*            <AddIconOutline /> Add New*/}
                    {/*        </Button>*/}
                    {/*    </Link>*/}
                    {/*</Col>*/}
                    <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                        <Form.Group className="mb-3" >
                            <Select
                                options={searchoption}
                                className="basic-multi-select"
                                onChange={optionsSearch}
                                value={searchBy}
                                styles={colourStyles}
                            />
                        </Form.Group>
                    </Col>

                </Row>
                <Row>
                    <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                        <Form.Group className="mb-3" >
                            <Select
                                options={statusOption}
                                className="basic-multi-select"
                                onChange={optionsStatus}
                                value={userStatus}
                                styles={colourStyles}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                        <Form.Group className="mb-3" >
                            <Select
                                options={categoryOption}
                                className="basic-multi-select"
                                onChange={optionsCategory}
                                value={category}
                                styles={colourStyles}
                            />
                        </Form.Group>
                    </Col>
                    {/*<Col xs={12} sm={6} md={3} lg={3} xl={3}>*/}
                    {/*    <Form.Group className="mb-3" >*/}
                    {/*        <Form.Select*/}
                    {/*            id={"type"}*/}
                    {/*            type="text"*/}
                    {/*            placeholder="Filter Type"*/}
                    {/*            onChange={(e) => filterType(e.target.value)}*/}
                    {/*            value={type}*/}
                    {/*        >*/}
                    {/*            <option selected disabled value="">Filter Type</option>*/}
                    {/*            <option value={""}>All</option>*/}
                    {/*            <option value={"User"}>User</option>*/}
                    {/*            <option value={"trainer"}>Trainer</option>*/}
                    {/*            <option value={"Admin"}>Admin</option>*/}
                    {/*        </Form.Select>*/}
                    {/*    </Form.Group>*/}
                    {/*</Col>*/}
                    <Col xs={12} sm={6} md={{ span: 4, offset: 0 }} lg={{ span: 4, offset: 0 }} xl={{ span: 4, offset: 0 }}>
                        <Button className={"btn btn-filter-theme h40 w-100"} onClick={()=>{
                            setUserStatus({label:"Filter Status",value:null})
                            setSearchBy({label:"Search By",value:null})
                            setCategory({label:"Filter Category",value:null})
                        }}>
                            Reset Filters
                        </Button>
                    </Col>
                </Row>
            </div>
            <div className="theme-dataGrid dark-theme products-dataGrid">
                <DataTable
                    pagination
                    columns={columns}
                    data={rows}
                    // actions={actionsMemo}
                    // data={data}
                    progressPending={loading}
                    customStyles={customStyles}
                    selectableRows={false}
                    // striped
                    highlightOnHover
                    theme='solarized'
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationPerPage={perPage}
                    onChangePage={(page) => {
                        setPage(page);
                    }}
                    onChangeRowsPerPage={(currentRowsPerPage) => {
                        setPerPage(currentRowsPerPage)
                    }}
                    progressComponent={<BarLoader color={'rgb(139, 77, 198)'} loading={loading} css={'marginTop: 10px'}
                                                  height={'4'} width={'100%'} />}
                />
            </div>
            {/*<ImagePreview*/}
            {/*    show={showModal}*/}
            {/*    onHide={handleCloseModal}*/}
            {/*    size="lg"*/}
            {/*    content={selectedImage}*/}
            {/*/>*/}
        </div>
    )
}

export default Business
