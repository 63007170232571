import React, {useEffect, useRef, useState} from 'react'
import swal from "sweetalert"
import { Link, useHistory } from "react-router-dom"
import { Button, Col, Dropdown, DropdownButton, FormControl, InputGroup, Row, Form } from "react-bootstrap"
import { AddIconOutline, MenuDots, SearchIcon } from "../../components/SvgIcons"
import { toast } from "react-toastify";
import DataTable,{createTheme} from 'react-data-table-component';
import BarLoader from "react-spinners/BarLoader";
import {constants, customStyles, GENDERS, STATUS} from "../../utils/constants";
import {DeleteUser, GetUsers, UpdateUser} from "../../services/users.service";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {colourStyles} from "../../utils/helpers";
import Select from "react-select";

function Users() {
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(constants.PERPAGE);
    const [rows, setRows] = useState([{
        id:1,
        name: 'bebark',
        image: 'https://fastly.picsum.photos/id/288/200/300.jpg?hmac=45WLionXnoogi0-njKuSNnVY5hnswMhf-CrxwzKTcrc',
        email:'-',
        gender:'male',
        dob:  '-',
        status: '-',
        address: '-',
        role:'user'
    }]);
    const [keyword, setKeyword] = useState('');
    const [userStatus, setUserStatus] = useState({label:"Filter Status",value:''});
    const [type, setType] = useState('');
    const [value, setValue] = useState('');
    const [email, setEmail] = useState(false);
    const typingTimeoutRef = useRef(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const [statusOption, setStatusOption] = useState([
        { value: "1", label: "Approved" },
        { value: "0", label: "Not Approved" },
    ]);

    createTheme('solarized', {
        text: {
            primary: '#ffffff',
            secondary: '#929399',
        },
        background: {
            default: '#0d0f0f',
        },
        context: {
            background: '#0d0f0f',
            text: '#929399',
        },
        highlightOnHover: {
            default: 'rgb(139 77 198)',
            text: '#ffffff',
        },
        divider: {
            default: '#929399',
        },
        action: {
            button: 'rgba(0,0,0,.54)',
            hover: 'rgba(0,0,0,.08)',
            disabled: 'rgba(0,0,0,.12)',
        },
    }, 'dark');

    useEffect(async () => {
        await getUsers();
    }, [keyword, page, perPage,userStatus])

    const getUsers = async () => {
        setLoading(true)
        await GetUsers(keyword, page, perPage,0,userStatus?.value).then((result) => {
            if (result?.success) {
                if (result?.data) {
                    const rowData = [];
                    setPage(result?.data?.pagination?.page)
                    setTotalRows(result.data.pagination.total);
                    let page = result.data.pagination.page-1
                    let counter = (page*result.data.pagination.limit)+1;
                    result.data.users.map((dt, index) => {
                        rowData.push({
                            sno: counter++,
                            id:dt._id || '-',
                            name: dt.username || '-',
                            // image: dt.image || '-',
                            email: dt.email || '-',
                            // userStatus: GENDERS[dt?.userStatus] || '-',
                            // dob: new Date(dt.dob).toLocaleDateString("en-US", {
                            //     month: "2-digit",
                            //     day: "2-digit",
                            //     year: "numeric"
                            // }) || '-',
                            status: STATUS[dt.isApproved] || '-',
                            // address: dt.address || '-',
                            // user_package: dt.user_package && dt.user_package?.name || '-',
                            role:dt.role || '-'
                        });

                    })
                    setRows(rowData);
                    setLoading(false)
                }
            } else {
                setLoading(false)
                setRows([]);
            }
        }).catch((error) => {
            setLoading(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else if(error.response.status == 404) {
                setRows([]);
            }else {
                return toast.error(error.response.data.message);
            }
        })
    }





    const onUserDelete = (id) => {
        swal({
            title: "Are you sure?",
            text: "Are you sure you want to delete this user?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willShip) => {
            //Delete  API
            if (willShip) {
                await DeleteUser(id).then((result) => {
                    if (result.success) {
                        swal(result.message, {
                            icon: "success",
                        });
                        getUsers();
                    } else {
                        toast.error(result.message);
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        EmptyLocalStorage()
                        history.push('/');
                    } else {
                        return toast.error(error.response.data.message);
                    }
                })
            }
        });
    }

    const onEdit = (data) => {
        history.push('/users/edit-user/' + data.id, {
            data: data
        });
    }

    const onView = (data) => {
        history.push('/users/view-user/' + data.id, {
            data: data
        });
    }

    const onApprove = async (data,status) => {
        swal({
            title: "Are you sure?",
            text: `Are you sure you want to ${status ? 'approve' : 'reject'} this user?`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willShip) => {
            //Delete  API
            if (willShip) {
                await UpdateUser(data.id, {isApproved:status}).then((result) => {
                    if (!result.error) {
                        swal(result.msg, {
                            icon: "success",
                        });
                        getUsers();
                    } else {
                        toast.error(result.msg);
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        EmptyLocalStorage()
                        history.push('/');
                    } else {
                        return toast.error(error.response.data.message);
                    }
                })
            }
        });
    }


    const userActions = (data) => {
        return (
            <DropdownButton align="end" className={"actionDropdown"} id="dropdown-item-button" title={<MenuDots />}>
                <Dropdown.Item as="button" onClick={() => onEdit(data)}>Edit</Dropdown.Item>
                <Dropdown.Item as="button" onClick={() => onView(data)}>View</Dropdown.Item>
                {/*{data?.status === STATUS[null] &&  <Dropdown.Item as="button" onClick={() => onApprove(data,true)}>Approve</Dropdown.Item>}*/}
                {/*{data?.status === STATUS[null] &&  <Dropdown.Item as="button" onClick={() => onApprove(data,false)}>Reject</Dropdown.Item>}*/}
                <Dropdown.Item as="button" onClick={() => onUserDelete(data.id)}>Delete</Dropdown.Item>
            </DropdownButton>
        )
    }

    const handleImageClick = (imageSrc) => {
        setSelectedImage(imageSrc);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const columns = [
        {
            id: 'sno',
            name: 'ID',
           maxWidth: '50px',
            sortable: true,
            selector: rowData => rowData.sno,
        },
        // {
        //     id: 'image',
        //     name: 'Image',
        //     maxWidth: '100px',
        //     selector: rowData => rowData.image,
        //     format: rowData => <img src={rowData.image}
        //                             onClick={() => handleImageClick(rowData.image)}
        //                             onError={({ currentTarget }) => {
        //                                 currentTarget.onerror = null;
        //                                 currentTarget.src = constants.IMAGENOTFOUND;
        //                             }}
        //                             alt={'Broken'}
        //                             className={"img-table img-thumbnail"} crossOrigin={"anonymous"} />
        // },
        {
            id: 'full_name',
            maxWidth: '600px',
            name: 'Name',
            sortable: true,
            selector: rowData => rowData.name,
        },
        {
            id: 'email',
            name: 'Email',
            sortable: true,
            grow: false,
            selector: rowData => rowData.email,
        },
        {
            id: 'status',
            name: 'Status',
            sortable: true,
            center: true,
            selector: rowData => rowData.status,
        },
        // {
        //     id: 'dob',
        //     name: 'DOB',
        //     maxWidth: '100px',
        //     center: true,
        //     selector: rowData => rowData.dob,
        // },
        // {
        //     id: 'user_package',
        //     name: 'Package',
        //     maxWidth: '150px',
        //     center: true,
        //     selector: rowData => rowData.user_package,
        // },
        // {
        //     id: 'type',
        //     name: 'Type',
        //     maxWidth: '120px',
        //     center: true,
        //     selector: rowData => rowData.type,
        // },
        // {
        //     id: 'address',
        //     name: 'Address',
        //     selector: rowData => rowData.address,
        // },
        {
            id: 'role',
            name: 'Roles',
            grow: false,
            center: true,
            sortable: true,
            selector: rowData => rowData.role,
        },
        {
            id: 'action',
            name: 'Action',
            center: true,
            cell: rowData => userActions(rowData)
        },
    ];

    const filterStatus = async (value) => {
        setUserStatus(value)
    }
    // const filterType = async (value) => {
    //     setType(value)
    // }
    const filterFrom = async (value) => {
        setValue(value)
        if(value == 1){
            setEmail(true)
        }else{
            setEmail(false)
        }

    }

    const handleKeyUp = (e) => {
        clearTimeout(typingTimeoutRef.current);

        const value = e.target.value;
        if (value.length > 2) {
            typingTimeoutRef.current = setTimeout(() => {
                setKeyword(value);
            }, 500); // Set keyword after 500 milliseconds of inactivity
        } else {
            setKeyword(null);
        }
    };
    const optionsStatus = (selectedOptions) => {
        setUserStatus(selectedOptions || {});
    }


    return (
        <div className={"usersPage"}>
            {/*<h1 className="page-heading">Users ({totalRows})</h1>*/}
            <h1 className="page-heading">Users</h1>
            <div className={"customOrderFilters"}>
                <Row>
                    <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                        <InputGroup className="mb-3 search-group">
                            <InputGroup.Text id="basic-addon1"><SearchIcon /></InputGroup.Text>
                            <FormControl
                                placeholder="Search"
                                aria-label="Search"
                                aria-describedby="Search"
                                maxLength={"255"}
                                onKeyUp={handleKeyUp}
                            />
                        </InputGroup>
                    </Col>

                    <Col xs={12} sm={12} md={{span:2 ,offset:2}} lg={{span:2 ,offset:2}} xl={{span:2 ,offset:2}}>

                    </Col>
                    <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                        <Link to={"/users/add-user"}>
                            <Button className={"btn btn-green-theme h40 w-100 mb-md-0 mb-sm-3 mb-3"}>
                                <AddIconOutline /> Add New
                            </Button>
                        </Link>
                    </Col>
                </Row>
                <Row>
                    {/*<Col xs={12} sm={6} md={3} lg={3} xl={3}>*/}
                    {/*    <Form.Group className="mb-3" >*/}
                    {/*        <Form.Select*/}
                    {/*            id={"filter"}*/}
                    {/*            type="text"*/}
                    {/*            placeholder="Search From"*/}
                    {/*            onChange={(e) => filterFrom(e.target.value)}*/}
                    {/*            value={value}*/}
                    {/*        >*/}
                    {/*            <option selected disabled value="">Search From</option>*/}
                    {/*            <option value={"0"}>Name</option>*/}
                    {/*            <option value={"1"}>Email</option>*/}
                    {/*        </Form.Select>*/}
                    {/*    </Form.Group>*/}
                    {/*</Col>*/}
                    <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                        <Form.Group className="mb-3" >
                            <Select
                                options={statusOption}
                                className="basic-multi-select"
                                onChange={optionsStatus}
                                value={userStatus}
                                styles={colourStyles}
                            />
                        </Form.Group>
                    </Col>
                    {/*<Col xs={12} sm={6} md={3} lg={3} xl={3}>*/}
                    {/*    <Form.Group className="mb-3" >*/}
                    {/*        <Form.Select*/}
                    {/*            id={"type"}*/}
                    {/*            type="text"*/}
                    {/*            placeholder="Filter Type"*/}
                    {/*            onChange={(e) => filterType(e.target.value)}*/}
                    {/*            value={type}*/}
                    {/*        >*/}
                    {/*            <option selected disabled value="">Filter Type</option>*/}
                    {/*            <option value={""}>All</option>*/}
                    {/*            <option value={"User"}>User</option>*/}
                    {/*            <option value={"trainer"}>Trainer</option>*/}
                    {/*            <option value={"Admin"}>Admin</option>*/}
                    {/*        </Form.Select>*/}
                    {/*    </Form.Group>*/}
                    {/*</Col>*/}
                    <Col xs={12} sm={6} md={{ span: 6, offset: 0 }} lg={{ span: 6, offset: 3 }} xl={{ span: 4, offset: 4 }}>
                        <Button className={"btn btn-filter-theme h40 w-100"} onClick={()=>{
                            setValue('')
                            setType('')
                            setUserStatus({label:"Filter Status",value:''})
                        }}>
                           Reset Filters
                        </Button>
                    </Col>
                </Row>
            </div>
            <div className="theme-dataGrid dark-theme products-dataGrid">
                <DataTable
                    pagination
                    columns={columns}
                    data={rows}
                    // actions={actionsMemo}
                    // data={data}
                    progressPending={loading}
                    customStyles={customStyles}
                    selectableRows={false}
                    // striped
                    highlightOnHover
                    theme='solarized'
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationPerPage={perPage}
                    onChangePage={(page) => {
                        setPage(page);
                    }}
                    onChangeRowsPerPage={(currentRowsPerPage) => {
                        setPerPage(currentRowsPerPage)
                    }}
                    progressComponent={<BarLoader color={'rgb(139, 77, 198)'} loading={loading} css={'marginTop: 10px'}
                                                  height={'4'} width={'100%'} />}
                />
            </div>
            {/*<ImagePreview*/}
            {/*    show={showModal}*/}
            {/*    onHide={handleCloseModal}*/}
            {/*    size="lg"*/}
            {/*    content={selectedImage}*/}
            {/*/>*/}
        </div>
    )
}

export default Users
