import React from 'react';
import DataTable from 'react-data-table-component';
import { customStyles } from '../../utils/constants';
import { BarLoader } from 'react-spinners';

const RegisterUserTable = ({ rows, totalRows, page, loading, setPerPage, perPage, setPage, columns ,theme}) => {


    // ================== Tables Columns ====================
    return <DataTable
        title=""
        columns={columns}
        data={rows}
        progressPending={loading}
        customStyles={customStyles}
        selectableRows={false}
        // striped
        theme={theme}
        highlightOnHover
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        paginationPerPage={perPage}
        onChangePage={(page) => {
            setPage(page);
        }}
        onChangeRowsPerPage={(currentRowsPerPage) => {
            setPerPage(currentRowsPerPage);
        }}
        progressComponent={
            <BarLoader
                color={"#8b4dc6"}
                loading={loading}
                css={{ marginTop: "10px" }}
                height={"4"}
                width={"100%"}
            />
        } />;
};

export default RegisterUserTable;
