import React, { useState, useEffect, useCallback } from "react";
import {Link, useHistory, useLocation} from "react-router-dom";
import {
    Button,
    Col,
    Row,
    Tab,
    Tabs,
    Form, DropdownButton, Dropdown,
} from "react-bootstrap";
import { toast } from "react-toastify";
import debounce from "lodash.debounce";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import {AddIconOutline, MenuDots} from "../../components/SvgIcons";
import SearchBar from "../../components/SearchBar";
import RegisterUserTable from "./RegisterUsersTable";
import {GetBusinessReports, GetReports, GetUserReports} from "../../services/reports.service";
import { exportToExcel } from "../../utils/excelFileDownload";
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { BarLoader } from "react-spinners";
import DataTable, {createTheme} from "react-data-table-component";
import {STATUS} from "../../utils/constants";
import {DeleteTransaction, GetAllTransactions, GetAllTransactionsGroupByEvent} from "../../services/payment.service";
import swal from "sweetalert";
import {DeleteFaq} from "../../services/faq.api.service";
import Select from "react-select";
import {colourStyles} from "../../utils/helpers";

function Payment() {
    const history = useHistory();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [key, setKey] = useState(location.state ? location.state.type : "1");
    const [rows, setRows] = useState([]);
    const [totalRows, setTotalRows] = useState();
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [paymentStatus, setPaymentStatus] = useState({label:"Filter Status",value:''});
    const [statusOption, setStatusOption] = useState([
        { value: "succeeded", label: "Succeeded" },
        { value: "failed", label: "Failed" },
    ]);
    const [searchTerm, setSearchTerm] = useState("");
    const maxYear = new Date().getFullYear();
    createTheme('solarized', {
        text: {
            primary: '#ffffff',
            secondary: '#929399',
        },
        background: {
            default: '#0d0f0f',
        },
        context: {
            background: '#0d0f0f',
            text: '#929399',
        },
        divider: {
            default: '#929399',
        },
        highlightOnHover: {
            default: 'rgb(139 77 198)',
            text: '#ffffff',
        },
        action: {
            button: 'rgba(0,0,0,.54)',
            hover: 'rgba(0,0,0,.08)',
            disabled: 'rgba(0,0,0,.12)',
        },
    }, 'dark');

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    const isEndDateValid = () => startDate && endDate && endDate >= startDate;

    const handleResetFunction = () => {
        setStartDate();
        setEndDate();
        setSearchTerm("");
    };

    const formatStartDate = startDate ? moment(startDate).format('YYYY-MM-DD') : '';
    const formatEndDate = endDate ? moment(endDate).format('YYYY-MM-DD') : '';


    let Sparams = {
        key,
        searchTerm,
        formatStartDate,
        formatEndDate
    }
    const handleDatePickerClose = () => {
        init(Sparams);
    };



    const init = async (Sparams) => {

        setLoading(true);
        setKey(Sparams.key);
        try {

            const reportParams = {
                eventName: Sparams?.searchTerm,
                ...(paymentStatus?.value ? {status: paymentStatus?.value} : null),
                paginate: true,
                page,
                limit:perPage,
                ...(Sparams?.formatStartDate ? {startDate: Sparams?.formatStartDate} : null),
                ...(Sparams?.formatEndDate ? {endDate: Sparams?.formatEndDate} : null),
            }
            let result;
             if(key === '1'){
                 result = await GetAllTransactions(reportParams);
             }else{
                 result = await GetAllTransactionsGroupByEvent(reportParams);
             }


            if (result.success) {

                setTotalRows(result?.data?.pagination?.total);
                let page = result?.data?.pagination?.page-1
                let counter = (page*result?.data?.pagination?.limit)+1;
                if(key === '1'){
                    console.log( result.data?.transactions," result?.transaction")
                    const rowData = result.data?.transactions?.map((dt, index) => ({
                        sno: counter++,
                        id:dt._id || '-',
                        customer_name: dt.user?.username,
                        customer_id: dt.user?._id || '-',
                        host_name: dt.host?.username ,
                        host_id: dt.host?._id || '-',
                        event_name: dt.event?.username|| '-',
                        status: dt.status || '-',
                        totalPrice: (dt.price).toFixed(2) + '$' || '-',
                        commission_percent: dt.commission_percent + '%' || '-',
                        totalCommissionAmount: (dt.commission_amount).toFixed(2) + '$' || '-',
                        totalAmountAfterCommission: (dt.amount_after_commission).toFixed(2) + '$' || '-',
                        createdAt: moment(dt.created_at).fromNow() || '-',
                    }));
                    setRows(rowData);
                }else{
                    const rowData = result.data?.transactions?.map((dt, index) => ({
                        sno: counter++,
                        id:dt._id || '-',
                        eventName: dt.eventName || '-',
                        eventPlace: dt.eventPlace || '-',
                        maxParticipants: dt.maxParticipants || '-',
                        totalPrice:(dt.totalPrice).toFixed(2) + ' $' || '-',
                        commission_percent: (dt.commission_percent).toFixed(2) + ' %' || '-',
                        totalCommissionAmount: (dt.totalCommissionAmount).toFixed(2) + ' $' || '-',
                        totalAmountAfterCommission: (dt.totalAmountAfterCommission).toFixed(2) + ' $' || '-',

                    }));
                    setRows(rowData);
                }
                setLoading(false);
            } else {
                setLoading(false);
                toast.error('in else');
            }

        } catch (error) {
            console.error("Error:", error);
            setLoading(false);
            if (error.response && error.response.status === 401) {
                EmptyLocalStorage();
                history.push("/");
            } else {
                toast.error(
                    error.response ? error.response.data.message : "An error occurred"
                );
            }
        }
    }

    useEffect(() => {
        init(Sparams);
    }, [endDate, page, perPage, key,paymentStatus]);

    const onDelete = (id) => {
        swal({
            title: "Are you sure?",
            text: "Are you sure you want to delete this Transaction?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willShip) => {
            if (willShip) {
                await DeleteTransaction(id)
                    .then((result) => {
                        if (result.success) {
                            swal(result.message, {
                                icon: "success",
                            });
                            init(Sparams);
                        } else {
                            toast.error(result.message);
                        }
                    })
                    .catch((error) => {
                        if (error.response && error.response.status === 401) {
                            EmptyLocalStorage();
                            history.push("/");
                        } else {
                            return toast.error(
                                error.response
                                    ? error.response.data.message
                                    : "An error occurred"
                            );
                        }
                    });
            }
        });
    };

    const userActions = (data) => (
        <DropdownButton
            align="end"
            className={"actionDropdown"}
            id="dropdown-item-button"
            title={<MenuDots />}
        >
            <Dropdown.Item as="button" onClick={() => onDelete(data.id)}>
                Delete
            </Dropdown.Item>
        </DropdownButton>
    );
    const columnRenderer = (key) => {
        const columns = [
            {
                name: "S.No",
                maxWidth: "60px",
                selector: "sno",
                center: true,
                sortable: true,
            },
            {
                name: "Customer Name",
                cell: (row, index) => row.customer_name ? <Link to={`users/view-user/${row.customer_id}`} className={'clickable'}>{row.customer_name}</Link> : '-',
                width: "200px",
                center: false,
                sortable: true,
            },
            {
                name: "Host Name",
                selector: "host_name",
                cell: (row, index) => row.host_name ? <Link to={`users/view-user/${row?.host_id}`} className={'clickable'}>{row.host_name}</Link> : '-',
                width: "200px",
                center: false,
                sortable: true,
            },
            {
                name: "Event Name",
                selector: "event_name",
                width: "200px",
                center: false,
                sortable: true,
            },
            {
                name: "Status",
                selector: "status",
                width: "150px",
                center: true,
                sortable: true,
            },
            {
                name: "Total Price",
                selector: "totalPrice",
                width: "150px",
                center: true,
                sortable: true,
            },
            {
                name: "Commission %",
                selector: "commission_percent",
                width: "150px",
                center: true,
                sortable: true,
            },
            {
                name: "Total Commission Amount",
                selector: "totalCommissionAmount",
                width: "250px",
                center: true,
                sortable: true,
            },
            {
                name: "Total Amount After Commission",
                selector: "totalAmountAfterCommission",
                width: "250px",
                center: true,
                sortable: true,
            },
            {
                name: "Action",
                center: true,
                cell: (rowData) => userActions(rowData),
            },

        ];
        const columnsBusiness = [
            {
                id: 'id',
                name: 'Id',
                selector: "sno",
                sortable: true,
            },

            {
                name: "Event Name",
                selector: "eventName",
                width: "200px",
                center: false,
                sortable: true,
            },
            {
                name: "Event Place",
                selector: "eventPlace",
                width: "300px",
                center: false,
                sortable: true,
            },
            {
                name: "Max Participants",
                selector: "maxParticipants",
                width: "250px",
                center: false,
                sortable: true,
            },
            {
                name: "Total Price",
                selector: "totalPrice",
                maxWidth: "200px",
                center: true,
                sortable: true,
            },
            {
                name: "Commission %",
                selector: "commission_percent",
                width: "250px",
                center: true,
                sortable: true,
            },
            {
                name: "Total Commission Amount",
                selector: "totalCommissionAmount",
                width: "250px",
                center: true,
                sortable: true,
            },
            {
                name: "Total Amount After Commission",
                selector: "totalAmountAfterCommission",
                width: "250px",
                center: true,
                sortable: true,
            },
        ];

        return key == '1' ? columns : columnsBusiness;
    };

    const handleExcelDownload = async () => {
        if (rows?.length > 0) {
            let rowData;
            const reportParams = {
                eventName: Sparams?.searchTerm,
                status:paymentStatus?.value,
                paginate: false,
                page,
                limit:perPage,
                ...(Sparams?.formatStartDate ? {startDate: Sparams?.formatStartDate} : null),
                ...(Sparams?.formatEndDate ? {endDate: Sparams?.formatEndDate} : null),
            }
            let result;
            if(key === '1'){
                result = await GetAllTransactions(reportParams);
            }else{
                result = await GetAllTransactionsGroupByEvent(reportParams);
            }


            if (result.success) {
                if(key === '1'){
                     rowData = result?.data.transactions?.map((dt, index) => ({
                        sno: index + 1,
                         id:dt._id || '-',
                         customer_name: dt.user?.username || '-',
                         host_name: dt.host?.username || '-',
                         event_name: dt.event?.username|| '-',
                         status: dt.status || '-',
                         totalPrice: dt.price + '$' || '-',
                         commission_percent: dt.commission_percent + '%' || '-',
                         totalCommissionAmount: dt.commission_amount + '$' || '-',
                         totalAmountAfterCommission: dt.amount_after_commission + '$' || '-',
                         createdAt: moment(dt.created_at).fromNow() || '-',

                    }));
                }else{
                       rowData = result?.data.transactions?.map((dt, index) => ({
                           sno: index + 1,
                           id:dt._id || '-',
                           eventName: dt.eventName || '-',
                           eventPlace: dt.eventPlace || '-',
                           maxParticipants: dt.maxParticipants || '-',
                           totalPrice: dt.totalPrice + '$' || '-',
                           commission_percent: dt.commission_percent + '%' || '-',
                           totalCommissionAmount: dt.totalCommissionAmount + '$' || '-',
                           totalAmountAfterCommission: dt.totalAmountAfterCommission + '$' || '-',

                    }));
                }
            } else {
                toast.error('Error in exporting data');
            }

            exportToExcel(rowData, columnRenderer(key), {
                mainHeading: "Payments",
                subHeading: `Following is data report for ${ key === "1"
                        ? "Transactions"
                        : "Transactions Group By Event"
                    }`,
            });
        } else {
            toast.warning("No data found")
        }

    };

    const debouncedRequest = debounce(
        (searchTerm) => {
            Sparams.searchTerm = searchTerm;
            init(Sparams)
        },
        500
    );
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            border: '1px solid #c4c4c4',
            borderRadius: '2px',
            boxShadow: state.isFocused ? '0 0 0 1px #ff00e6' : null,
            fontSize: window.innerWidth >= 1366 ? '13px' : '14px',
            height: '40px',
            '&:hover': {
                border: '1px solid #ff00e6',
                cursor: 'pointer'
            },
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? 'rgb(139, 77, 198)' : state.isHovered ? '#ffe6ff' : null,
            color: state.isSelected ? 'white' : 'black',
            '&:hover': {
                backgroundColor: 'rgb(139, 77, 198)',
                color: 'white',
                cursor: 'pointer'
            },
        }),
    };

    const handleTabSelect = (selectedKey) => {
        setSearchTerm("");

        if (selectedKey === "2") {
            setKey(selectedKey);
        } else {
            setKey('1');
        }
    };

    const optionsStatus = (selectedOptions) => {
        setPaymentStatus(selectedOptions || {});
    }

    return (
        <div className={"usersPage"}>
            <h1 className="page-heading">Payments</h1>
            <div className={"customOrderFilters mb-3"}>
                <Row className="g-1 mb-3">
                    <Col xs={12} sm={12} md={4} lg={3} xl={3} xxl={3} className="d-flex align-items-end">
                        <SearchBar
                            noMargin={true}
                            onSearch={(value) => debouncedRequest(value)}
                            placeholer={'Search By Event Name'}
                        />
                    </Col>

                    <Col xs={12} sm={12} md={2} lg={{span:2,offset:1}} xl={{span:2,offset:1}} xxl={{span:2,offset:2}}>
                        <div className="datepickerBox">
                            <DatePicker
                                selected={startDate}
                                onChange={handleStartDateChange}
                                dateFormat="dd-MM-yyyy"
                                maxDate={new Date()}
                                placeholderText="Filter Start Date"
                            />
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} xl={2} xxl={2}>
                        <div className="datepickerBox">
                            <DatePicker
                                selected={endDate}
                                onChange={handleEndDateChange}
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate}
                                maxDate={new Date()}
                                dateFormat="dd-MM-yyyy"
                                placeholderText="Filter End Date"
                                onClose={handleDatePickerClose}
                            />
                            {!isEndDateValid() && (
                                <></>
                            )}
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={1} xl={1} xxl={1}>
                        <Button onClick={handleResetFunction} className="transparentBtn h40">
                            Reset
                        </Button>
                    </Col>

                    <Col xs={12} sm={12} md={4} lg={3} xl={3} xxl={2}>
                        <div className="d-flex justify-content-end align-items-end h-100 reportBtn-wrapper">
                            <Link to={"/settings/edit-commission-percentage"}>
                            <Button className={"btn btn-green-theme h40 reportBtn"}
                            >
                                <AddIconOutline /> Edit Comission %
                            </Button>
                            </Link>
                        </div>
                    </Col>
                </Row>
                <Row className="g-1">
                    <Col xs={12} sm={6} md={4} lg={2} xl={2}>
                        {key == 1 &&
                        <Form.Group >
                            <Select
                                options={statusOption}
                                className="basic-multi-select"
                                onChange={optionsStatus}
                                value={paymentStatus}
                                styles={colourStyles}
                            />
                        </Form.Group>
                        }

                    </Col>
                    <Col xs={12} sm={6} md={4} lg={2} xl={2}>
                        {key == 1 &&
                        <Button className={"btn btn-filter-theme h40 w-100"} onClick={()=>{
                            setPaymentStatus({label:"Filter Status",value:''})
                        }}>
                            Reset Filter
                        </Button>
                        }

                    </Col>
                    <Col xs={12} sm={12} md={4} lg={{span:2,offset:2}} xl={{span:2,offset:6}} xxl={{span:2,offset:6}} >
                        <div className="d-flex justify-content-end align-items-end h-100 reportBtn-wrapper">
                            <Button
                                onClick={handleExcelDownload}
                                className={"btn btn-green-theme h40 reportBtn"}
                            >
                                <AddIconOutline /> Download Report
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="tabs-wrapper position-relative">
                <Tabs
                    id="statsAndAnalyticsTabs"
                    activeKey={key}
                    onSelect={handleTabSelect}
                >
                    <Tab eventKey="1" title="Transactions">
                        <DataTable
                            title=""
                            columns={columnRenderer(key)}
                            data={rows}
                            progressPending={loading}
                            customStyles={customStyles}
                            selectableRows={false}
                            // striped
                            theme='solarized'
                            highlightOnHover
                            pagination
                            paginationServer
                            paginationTotalRows={totalRows}
                            paginationPerPage={perPage}

                            onChangePage={(page) => {
                                setPage(page);

                            }}
                            onChangeRowsPerPage={(currentRowsPerPage) => {
                                setPerPage(currentRowsPerPage);
                            }}
                            progressComponent={
                                <BarLoader
                                    color={"#8b4dc6"}
                                    loading={loading}
                                    css={{ marginTop: "10px" }}
                                    height={"4"}
                                    width={"100%"}
                                />
                            } />
                    </Tab>
                    <Tab eventKey="2" title="Transactions Group By Event">
                        <RegisterUserTable
                            theme='solarized'
                            columns={columnRenderer(key)}
                            rows={rows}
                            totalRows={totalRows}
                            page={page}
                            setPage={setPage}
                            loading={loading}
                            setPerPage={setPerPage}
                            perPage={perPage}
                        />
                    </Tab>
                </Tabs>
            </div>
        </div>
    );
}

export default Payment;
