import React, { useState, useEffect, useCallback } from "react";
import debounce from "lodash.debounce";
import swal from "sweetalert";
import { Link, useHistory } from "react-router-dom";
import {
  Button,
  Col,
  Dropdown,
  DropdownButton,
  Modal,
  Row,
} from "react-bootstrap";
import DataTable, {createTheme} from "react-data-table-component";
import BarLoader from "react-spinners/BarLoader";
import { toast } from "react-toastify";
import { customStyles } from "../../utils/constants";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import SearchBar from "../../components/SearchBar";
import { AddIconOutline, MenuDots } from "../../components/SvgIcons";
import { DeleteFaq, GetFaqs } from "../../services/faq.api.service";

function FaqsPage() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [totalRows, setTotalRows] = useState(10);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [showModal, setShowModal] = useState(false);
  const [citiesList, setCitiesList] = useState([]);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  createTheme('solarized', {
    text: {
      primary: '#ffffff',
      secondary: '#929399',
    },
    background: {
      default: '#0d0f0f',
    },
    context: {
      background: '#0d0f0f',
      text: '#929399',
    },
    divider: {
      default: '#929399',
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)',
    },
  }, 'dark');
  const init = useCallback(
    async (searchTerm) => {
      try {
        setLoading(true)
        const faqParams = {
          page,
          perPage,
          is_paginated: 1,
          query: searchTerm,
        };

        const result = await GetFaqs(faqParams);

        if (result.success) {
          const { faqs } = result;
          setTotalRows(faqs?.meta?.total);

          const rowData = faqs?.map((dt, index) => ({
            id: dt._id || "-",
            question: dt.question || "-",
            description: dt.answer || "-",
            created_ago: dt.createdAt || "-",
          }));

          setRows(rowData);
          setLoading(false);
          // Extract cities list from the first row (assuming all rows have the same cities)
          if (rowData.length > 0) {
            setCitiesList(rowData[0]?.description || []);
          }
        } else {
          setLoading(false);
          toast.error(result.message);
        }
      } catch (error) {
        console.error("Error:", error);

        setLoading(false);

        if (error.response && error.response.status === 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          toast.error(
            error.response ? error.response.data.message : "An error occurred"
          );
        }
      }
    },
    [history, page, perPage]
  );

  const onDelete = (id) => {
    swal({
      title: "Are you sure?",
      text: "Are you sure you want to delete this FAQ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willShip) => {
      if (willShip) {
        await DeleteFaq(id)
          .then((result) => {
            if (result.success) {
              swal(result.message, {
                icon: "success",
              });
              init();
            } else {
              toast.error(result.message);
            }
          })
          .catch((error) => {
            if (error.response && error.response.status === 401) {
              EmptyLocalStorage();
              history.push("/");
            } else {
              return toast.error(
                error.response
                  ? error.response.data.message
                  : "An error occurred"
              );
            }
          });
      }
    });
  };

  const onEdit = (data) => {
    history.push(`/faqs/edit-faqs/${data.id}`, {
      data: data,
    });
  };

  const userActions = (data) => (
    <DropdownButton
      align="end"
      className={"actionDropdown"}
      id="dropdown-item-button"
      title={<MenuDots />}
    >
      <Dropdown.Item as="button" onClick={() => onEdit(data)}>
        Edit
      </Dropdown.Item>
      <Dropdown.Item as="button" onClick={() => onDelete(data.id)}>
        Delete
      </Dropdown.Item>
    </DropdownButton>
  );

  const Description = (rowData) => {
    const data = rowData?.description || [];
    const truncatedData = data.split(' ').slice(0, 6).join(' ') + (data.split(' ').length > 6 ? ' ...' : '');

    const handleShowModalForCities = () => {
      setCitiesList(rowData?.description || []);
      handleShowModal();
    };

    return (
      <div className="d-flex align-items-center flex-wrap cursor-pointer" onClick={handleShowModalForCities}>
        <p className="m-0 text-light">{truncatedData}</p>
      </div>
    );
  };

  const columns = [
    {
      name: "S.No",
      maxWidth: "100px",
      center: true,
      cell: (row, index) => <span>{index + 1}</span>,
    },
    {
      name: "question",
      selector: "question",
      maxWidth: "100%",
      center: false,
      sortable: true,
    },
    {
      name: "description",
      selector: "description",
      maxWidth: "100%",
      sortable: true,
      center: true,
      cell: (rowData) => <Description {...rowData} />,
    },

    {
      name: "Action",
      center: true,
      cell: (rowData) => userActions(rowData),
    },
  ];

  //Debounce Seatch Input
  const debouncedRequest = debounce((searchTerm) => init(searchTerm), 500);

  useEffect(() => {
    init();
  }, [page, perPage]);

  return (
    <div className={"usersPage"}>
      <h1 className="page-heading">Frequently Asked Questions</h1>
      <div className={"customOrderFilters"}>
        <Row>
          <Col xs={12} sm={12} md={5} lg={5} xl={5}>
            <SearchBar onSearch={(value) => debouncedRequest(value)} />
          </Col>
          <Col
            xs={12}
            sm={12}
            md={{ span: 2, offset: 2 }}
            lg={{ span: 2, offset: 2 }}
            xl={{ span: 2, offset: 2 }}
          ></Col>
          <Col xs={12} sm={12} md={3} lg={3} xl={3}>
            <Link to="/faqs/add-faqs">
              <Button className={"btn btn-green-theme h40 w-100"}>
                <AddIconOutline /> Add FAQ's
              </Button>
            </Link>
          </Col>
        </Row>
      </div>
      <div className="theme-dataGrid products-dataGrid">
        <DataTable
          columns={columns}
          data={rows}
          progressPending={loading}
          customStyles={customStyles}
          selectableRows={false}
          // striped
          highlightOnHover
          pagination
          theme='solarized'
          paginationServer
          paginationTotalRows={totalRows}
          paginationPerPage={perPage}
          onChangePage={(page) => {
            setPage(page);
          }}
          onChangeRowsPerPage={(currentRowsPerPage) => {
            setPerPage(currentRowsPerPage);
          }}
          progressComponent={
            <BarLoader
              color={"#8b4dc6"}
              loading={loading}
              css={{ marginTop: "10px" }}
              height={"4"}
              width={"100%"}
            />
          }
        />
      </div>
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton closeVariant={'white'}>
          <h4 className="m-0">Description</h4>
        </Modal.Header>
        <Modal.Body>{citiesList}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className={"btn btn-green-theme h40 w-100"}
            onClick={handleCloseModal}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default FaqsPage;
