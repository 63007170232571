import React, { useLayoutEffect, useRef, useState } from "react";
import { Form, Label, Button, InputGroup, FormControl } from "react-bootstrap";
import { Logo } from "../../utils/Svg";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import {
  EmptyLocalStorage,
  ResetPasswordApi,
} from "../../services/auth/auth.service";
import { toast } from "react-toastify";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { FadeLoader } from "react-spinners";

function ResetPassword({ location }) {
  const history = useHistory();
  const [pass, TogglePass] = useState(true);
  const [confirmPass, ToggleConfirmPass] = useState(true);
  const [loader, setLoader] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  useLayoutEffect(() => {
    if (!location.state?.email && !location.state?.verificationCode) {
      history.push("/reset-password");
    }
  }, []);

  const password = useRef({});
  password.current = watch("password", "");

  const onSubmit = async (data) => {
    let email = location.state.email;
    let otp_code = location.state.verificationCode;
    let password = data.password;
    let password_confirmation = data.password_confirmation;
    setLoader(true);
    await ResetPasswordApi(email, password, password_confirmation)
      .then((data) => {
        setLoader(false);
        if (!data.error) {
          toast.success(data.msg);
          history.push("/");
        } else {
          toast.error(data.msg);
        }
      })
      .catch((error) => {
        setLoader(false);
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message);
        }
      });
  };

  return (
    <div className={"text-center authLayout"}>
      <Form className={"form-signin"} onSubmit={handleSubmit(onSubmit)}>
        <a href="/">
          <Logo />
        </a>
        <h1 className="h5 mb-3 font-weight-normal ">
          Reset Password
        </h1>
        <Form.Group className="mb-3">
          {/*<Form.Control*/}
          {/*    placeholder="Enter Verification Code"*/}
          {/*    {...register('verificationCode', {*/}
          {/*        required: {*/}
          {/*            value: "required",*/}
          {/*            message: "Verification code is required"*/}
          {/*        },*/}
          {/*        minLength: {*/}
          {/*            value: 4,*/}
          {/*            message: "Min length is 4"*/}
          {/*        },*/}
          {/*        maxLength: {*/}
          {/*            value: 4,*/}
          {/*            message: "Max length is 4"*/}
          {/*        },*/}
          {/*    })}*/}
          {/*    type="text"*/}
          {/*/>*/}
          {/*{errors.verificationCode &&*/}
          {/*<span className={"validation-error pr-5"} role="alert">{errors.verificationCode.message}</span>}*/}
          <div className={"pass-field  mb-3"}>
            <InputGroup controlId="passowrd">
              <FormControl

                placeholder="Enter New Password"
                autoComplete={"off"}
                {...register("password", {
                  minLength: {
                    value: 8,
                    message: "Min length is 8",
                  },
                  maxLength: {
                    value: 255,
                    message: "Max length is 255",
                  },
                })}
                type={pass ? "password" : "text"}
              />
              <Button
                variant="outline-secondary"
                id="button-addon2"
                onClick={() => TogglePass(!pass)}
              >
                {pass ? <FaEye /> : <FaEyeSlash />}
              </Button>
            </InputGroup>
            {errors.password && (
              <span className={"validation-error pr-5"} role="alert">
                {errors.password.message}
              </span>
            )}
          </div>
          <div className={"pass-field"}>
            <InputGroup controlId="password_confirmation">
              <FormControl
                placeholder="Confirm Password"
                autoComplete={"off"}
                {...register("password_confirmation", {
                  required: {
                    value: "required",
                    message: "Confirm password is required",
                  },
                  validate: (value) =>
                    value === password.current || "The passwords do not match",
                  minLength: {
                    value: 8,
                    message: "Min length is 8",
                  },
                  maxLength: {
                    value: 255,
                    message: "Max length is 255",
                  },
                })}
                type={confirmPass ? "password" : "text"}
              />
              <Button
                variant="outline-secondary"
                id="button-addon2"
                onClick={() => ToggleConfirmPass(!confirmPass)}
              >
                {confirmPass ? <FaEye /> : <FaEyeSlash />}
              </Button>
            </InputGroup>
            {errors.password_confirmation && (
              <span className={"validation-error pr-5"} role="alert">
                {errors.password_confirmation.message}
              </span>
            )}
          </div>
        </Form.Group>

        <div className="d-grid gap-2 loader_custom">
          {loader ? (
            <div className="spin-loader">
              {" "}
              <FadeLoader color={' rgb(139 77 198)'} height={10} />
            </div>
          ) : (
            <Button
              variant="primary"
              size="lg"
              type="submit"
              className={"btn-green-theme"}
            >
              Reset Password
            </Button>
          )}
        </div>
        <p className="mt-5 mb-3 text-muted">
          &copy; All rights reserved by Tekrevol {new Date().getFullYear()}
        </p>
      </Form>
    </div >
  );
}

export default ResetPassword;
