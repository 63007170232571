import React from "react";
import { Route, Redirect } from "react-router-dom";
import Header from "../components/Header";
import SideBar from "../components/SideBar";
import { Container, Row, Col } from "react-bootstrap";

function DashboardLayout({ children }) {
  return (
    <div>
      <div className="dashboard_layout_wrapper">
        <Header url={"/dashboard"} />
        <Container fluid>
          <Row>
            <aside
              className={
                "col-12 col-sm-12 col-md-3 d-none d-lg-block  sidebar-nav"
              }
            >
              <SideBar />
            </aside>
            <main className={"col-12 col-sm-12 col-lg-9 col-md-12"}>
              <div className={"main_content_wrapper"}>{children}</div>
            </main>
          </Row>
        </Container>
      </div>
    </div>
  );
}
const DashboardLayoutRoute = ({ isAuth, component: Component, ...rest }) => {
  // isAuth = true;
  isAuth = localStorage.getItem("AUTH_ACCESS_TOKEN") ? true : false;
  return (
    <>
      <Route
        {...rest}
        render={(matchProps) =>
          isAuth ? (
            <DashboardLayout>
              <Component {...matchProps} />
            </DashboardLayout>
          ) : (
            <Redirect
              to={{
                pathname: "/signin",
                state: { from: matchProps.location },
              }}
            />
          )
        }
      />
    </>
  );
};

export default DashboardLayoutRoute;
