import { apiUrl } from '../utils/constants';
import {DELETE, GET, POST, PUT, PUT_WITHOUT_ID} from './api.service.wrapper';

export const PostAddPage = async (data) => {
    let response = '';
    response = await POST(apiUrl.pages, data);
    return response;
}

export const GetPages = async (keyword = '', pages = '', perPage = '', is_paginate = 1, status = null) => {
    const response = await GET(apiUrl.pages + '?' + pages + '&' + perPage);
    return response;
}

export const GetPageById = async (id) => {
    let response = '';
    response = await GET(apiUrl.pages + '/' + id);
    return response;
}

export const GetPageBySlug = async (slug) => {
    let response = '';
    response = await GET(apiUrl.pages + '/' + slug);
    return response;
}


export const UpdatePage = async (id, data,endPoint) => {
    const response = await POST(apiUrl.cms + endPoint, data);
    return response;
}

export const DeletePage = async (id) => {
    const response = await DELETE(apiUrl.pages, id);
    return response;
}
