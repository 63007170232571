import { apiUrl } from "../utils/constants";
import { DELETE, GET, PATCH, POST, PUT, UPDATE } from "./api.service.wrapper";

export const GetAllTransactions = async (params) => {
    const response = await GET(apiUrl.transactions, params);
    return response;
}

export const GetAllTransactionsGroupByEvent = async (params) => {
    const response = await GET(apiUrl.transactions + '/events', params);
    return response;
}

export const GetSettings = async (params) => {
    const response = await GET(apiUrl.settings + '/get', params);
    return response;
}

export const UpdateCommissionPercent = async (data) => {
    const response = await UPDATE(apiUrl.settings , '/update',data);
    return response;
}

export const DeleteTransaction = async (id) => {
    const response = await DELETE(apiUrl.transactions, id);
    return response;
};