import { apiUrl } from "../utils/constants";
import { DELETE, GET, PATCH, POST, PUT, UPDATE } from "./api.service.wrapper";

export const GetAllPackages = async (params) => {
  const response = await GET(apiUrl.packages, params);
  return response;
};

export const AddPackages = async (data) => {
  const response = await POST(apiUrl.packages, data);
  return response;
};

export const UpdatePackages = async (id, data) => {
  const response = await UPDATE(apiUrl.packages, id, data);
  return response;
};

export const DeletePackages = async (id) => {
  const response = await DELETE(apiUrl.packages, id);
  return response;
};

// export const GetCategoriesById = async (id) => {
//   const response = await PATCH(apiUrl.categories, id);
//   return response;
// };
