import React, {useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import DashboardStat from "../../components/DashboardStat";
import {Link, useHistory} from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import {GetDashboardData} from "../../services/dashboard.service";
import {toast} from "react-toastify";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {Spin} from 'antd';

function Dashboard() {
  let history = useHistory();
  const [dashboardStats, setDashboardStats] = useState(0);
  const [Loading, setLoading] = useState(true);
  const [GraphData, setGraphData] = useState( [{ month: 'March 2023',
    registrations:0}, // Sample data for January

      { month: 'April 2023',
        registrations:0}, // Sample data for February
      { month: 'May 2023',
        registrations:0}]);

  const [state, setState] = useState({
    series: [
      {
        name: "Number of events created",
        data: [],
      },
    ],
    options: {
      chart: {
        height: 500,
        type: "bar",
        stacked: true,
        fontFamily: "Poppins, sans-serif",
        margin: {
          top: 20, // Add top margin for spacing
        },

      },
      plotOptions: {
        bar: {
          borderRadius: 0,
          dataLabels: {
            position: "top", // top, center, bottom
          },

        },
      },
      dataLabels: {
        enabled: true,
        formatter: (val) => val  ,
        offsetY: -30,
        style: {
          fontSize: "12px",
          colors: ["#ffffff"],
          fontWeight: "500",
        },
      },
      xaxis: {
        categories: [],
        position: "top",
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tooltip: {
          enabled: true,
        },
        labels: {
          style: {
            colors: "#ffffff", // Change x-axis label color here
          },
        },
      },
      yaxis: {
        min: 0,
        max: 8000,
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: true,
        },
        title: {
          text: "Number of events created",
          style: {
            color: "#fff",
            fontWeight: "500",
          },
        },
        labels: {
          show: true,
          formatter: (val) => val ,
          style: {
            colors: "#ffffff", // Change x-axis label color here
          },

        },
      },
      tooltip: {
        theme: "dark", // Change tooltip theme here
      },
      colors: ["rgb(139, 77, 198)"]
    },
  });

  const init = async () => {
    try {
      const response = await GetDashboardData();

      if (response.success) {

        if (response) {
          setDashboardStats(response.data);
          setGraphData(response.data.eventStats);
          setLoading(false);
        }
      } else {
        setLoading(false);
        toast.error(response.message);
      }
    } catch (error) {
      console.log("Messge", error);
      setLoading(false);

      if (error.response && error.response.status === 401) {
        EmptyLocalStorage();
        history.push("/");
      } else {
        toast.error(
          error.response ? error.response.data.message : "An error occurred"
        );
      }
    }

  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (GraphData) {
      const premiumUsersData = GraphData?.map((events) => ({
        x: events.month,
        y: events.registrations, // Assuming each user contributes 1 to the count
      }));
      const monthCounts = premiumUsersData.reduce((acc, entry) => {
        acc[entry.x] = (acc[entry.x] || 0) + entry.y;
        return acc;
      }, {});

      const allMonths =GraphData?.map((events)=> events.month ) ;

      const seriesData = allMonths.map((month) => ({
        x: month,
        y: monthCounts[month] || 0,
      }));

      setState((prevState) => ({
        ...prevState,
        series: [
          {
            name: "Events created",
            data: seriesData,
          },
        ],
        options: {
          ...prevState.options,
          xaxis: {
            ...prevState.options.xaxis,
            categories: allMonths,
          },
        },
      }));
    }
  }, [GraphData]);

  return (
    <div className="dashboard">
      <Row className={"mb-5 "} noGutters>
        <Col xs={12} sm={12} md={4} className={"mt-3"}>
          <Link to={"/users"} className={`${"linkStats"} ${Loading ? "shimmerBG" : null}`}>
            <DashboardStat
              title={"Total Registered Users"}
              countMain={Loading ?  <Spin/> : dashboardStats.users}
            />
          </Link>
        </Col>
        <Col xs={12} sm={12} md={4} className={"mt-3"}>
          <Link to={"/businesses"} className={`${"linkStats"} ${Loading ? "shimmerBG" : null}`}>
            <DashboardStat
              title={"Total Registered Businesses"}
              countMain={Loading ?  <Spin/> : dashboardStats.business }
            />
          </Link>
        </Col>
        <Col xs={12} sm={12} md={4} className={"mt-3"}>
          <Link to={"/dashboard"} className={`${"linkStats"} ${Loading ? "shimmerBG" : null}`}>
            <DashboardStat
              title={"Total House parties"}
              countMain={Loading ?  <Spin/> : dashboardStats.houseParties }
            />
          </Link>
        </Col>
        <Col xs={12} sm={12} md={4} className={"mt-3"}>
          <Link to={"/dashboard"} className={`${"linkStats"} ${Loading ? "shimmerBG" : null}`}>
            <DashboardStat
                title={"Total Events of clubs/Bars"}
                countMain={Loading ?  <Spin/> : dashboardStats.clubs }
            />
          </Link>
        </Col>
        <Col xs={12} sm={12} md={4} className={"mt-3"}>
          <Link to={"/businesses"} className={`${"linkStats"} ${Loading ? "shimmerBG" : null}`}>
            <DashboardStat
                title={"Unapproved Businesses"}
                countMain={Loading ?  <Spin/> : dashboardStats.unApprovedBusinesses }
            />
          </Link>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12}>
          <h4>Bar graph (Number of events created)</h4>
          <ReactApexChart
            options={state.options}
            series={state.series}
            type="bar"
            height={350}
          />
        </Col>
      </Row>
    </div>
  );
}

export default Dashboard;
