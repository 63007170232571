import { saveAs } from "file-saver";
import ExcelJS from "exceljs";
const alphabet = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
];
// Function to calculate the required cell height based on content and column width
const getRequiredCellHeight = (text, columnWidth) => {
    const defaultFontSize = 12; // Set your desired default font size
    const defaultLineHeight = 14; // Set your desired default line height
    const columnPadding = 10; // Set the padding value for the column
    // Split the text into lines based on line breaks
    const lines = text.split("\n");
    // Calculate the number of lines
    const numberOfLines = lines.length;
    // Calculate the required cell height based on the number of lines and line height
    const requiredHeight = numberOfLines * defaultLineHeight + columnPadding;
    // Calculate the maximum width of text in each line
    const maxWidth = lines.reduce((max, line) => {
        const lineWidth = line.length * defaultFontSize;
        return lineWidth > max ? lineWidth : max;
    }, 0);
    // Calculate the required column width based on the maximum width of text
    const requiredColumnWidth = maxWidth + columnPadding;
    // Update the column width if the required width is greater than the current width
    if (requiredColumnWidth > columnWidth) {
        columnWidth = requiredColumnWidth;
    }
    return {
        height: requiredHeight,
        width: columnWidth,
    };
};
export const exportToExcel = async (
    rows,
    columns,
    description,
    actionPrint = false
) => {

    var col = "";
    const columnArray = actionPrint ? columns.slice(0, -1) : columns.slice(0);
    // Create a new workbook
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(description?.mainHeading);
    // Add header row with customized content and formatting
    columnArray?.forEach((column, index) => {
        worksheet.getCell(2, index + 1).value = column.name;
        // Auto adjust column width based on content
        worksheet.getColumn(index + 1).width = column.name.length + 5;
    });
    columnArray?.forEach((column, index) => {
        col = index + 1;
        worksheet.getCell(3, index + 1).value = column.name;
        worksheet.getCell(3, index + 1).alignment = {
            vertical: "middle",
            horizontal: "center",
        };
        worksheet.getCell(3, index + 1).border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
        };
    });
    // Add main heading
    const HeaderLenght = alphabet.find((items, i) => i + 1 === col);
    worksheet.mergeCells(`A1:${HeaderLenght}1`);
    const mainHeadingCell = worksheet.getCell("A1");
    const mainHeading = description?.mainHeading.trim();
    mainHeadingCell.value = mainHeading;
    mainHeadingCell.font = {
        bold: true,
        size: 18,
    };
    mainHeadingCell.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true, // Allow text to wrap within the cell
    };
    worksheet.getRow(1).height = 30; // Adjust the height as needed
    // Add subheading 1
    worksheet.mergeCells(`A2:${HeaderLenght}2`);
    const subheading1Cell = worksheet.getCell("A2");
    subheading1Cell.value = description?.subHeading;
    subheading1Cell.font = {
        bold: true,
        size: 13,
        fgColor: { argb: "ffff" }, // Red color
    };
    subheading1Cell.alignment = {
        vertical: "middle",
        horizontal: "center",
    };

    // Add data rows with borders and center alignment
    rows.forEach((row, rowIndex) => {
        columnArray.forEach((column, columnIndex) => {
            const cell = worksheet.getCell(rowIndex + 4, columnIndex + 1);
            cell.value = row[column.selector];
            cell.alignment = {
                vertical: "middle",
                horizontal: "center",
            };
            cell.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
            };
        });
    });
    worksheet.eachRow({ includeEmpty: true }, (row) => {
        row.eachCell({ includeEmpty: true }, (cell) => {
            const cellText =
                cell.text && typeof cell.text === "string" ? cell.text : "";
            const columnWidth = worksheet.getColumn(cell.col).width;
            const cellHeight = getRequiredCellHeight(cellText, columnWidth);
            if (cellHeight > row.height) {
                row.height = cellHeight;
            }
        });
    });
    const excelFile = await workbook.xlsx.writeBuffer();
    const blob = new Blob([excelFile], { type: "application/octet-stream" });
    saveAs(blob, `${description?.mainHeading}.xlsx`);
};

export const exportToExcelWithoutFormat = async (
    rows,
    columns,
    description,
    actionPrint = false
) => {

    var col = "";
    const columnArray = actionPrint ? columns.slice(0, -1) : columns.slice(0);
    // Create a new workbook
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(description?.mainHeading);
    // Add header row with customized content and formatting
    columnArray?.forEach((column, index) => {
        worksheet.getCell(1, index + 1).value = column.name;
        // Auto adjust column width based on content
        worksheet.getColumn(index + 1).width = column.name.length + 5;
    });
    columnArray?.forEach((column, index) => {
        col = index + 1;
        worksheet.getCell(1, index + 1).value = column.name;
        worksheet.getCell(1, index + 1).alignment = {
            vertical: "middle",
            horizontal: "center",
        };
        // worksheet.getCell(3, index + 1).border = {
        //     top: { style: "thin" },
        //     left: { style: "thin" },
        //     bottom: { style: "thin" },
        //     right: { style: "thin" },
        // };
    });
    // // Add main heading
    // const HeaderLenght = alphabet.find((items, i) => i + 1 === col);
    // console.log("HeaderLenght", HeaderLenght);
    // worksheet.mergeCells(`A1:${HeaderLenght}1`);
    // const mainHeadingCell = worksheet.getCell("A1");
    // const mainHeading = description?.mainHeading.trim();
    // mainHeadingCell.value = mainHeading;
    // mainHeadingCell.font = {
    //     bold: true,
    //     size: 18,
    // };
    // mainHeadingCell.alignment = {
    //     vertical: "middle",
    //     horizontal: "center",
    //     wrapText: true, // Allow text to wrap within the cell
    // };
    // worksheet.getRow(1).height = 30; // Adjust the height as needed
    // // Add subheading 1
    // worksheet.mergeCells(`A2:${HeaderLenght}2`);
    // const subheading1Cell = worksheet.getCell("A2");
    // subheading1Cell.value = description?.subHeading;
    // subheading1Cell.font = {
    //     bold: true,
    //     size: 13,
    //     fgColor: { argb: "ffff" }, // Red color
    // };
    // subheading1Cell.alignment = {
    //     vertical: "middle",
    //     horizontal: "center",
    // };

    // Add data rows with borders and center alignment
    rows.forEach((row, rowIndex) => {
        columnArray.forEach((column, columnIndex) => {
            const cell = worksheet.getCell(rowIndex + 2, columnIndex + 1);
            cell.value = row[column.selector];
            cell.alignment = {
                vertical: "middle",
                horizontal: "center",
            };
            // cell.border = {
            //     top: { style: "thin" },
            //     left: { style: "thin" },
            //     bottom: { style: "thin" },
            //     right: { style: "thin" },
            // };
            cell.width = "100%"
        });
    });
    worksheet.eachRow({ includeEmpty: true }, (row) => {
        row.eachCell({ includeEmpty: true }, (cell) => {
            const cellText =
                cell.text && typeof cell.text === "string" ? cell.text : "";
            const columnWidth = worksheet.getColumn(cell.col).width;
            const cellHeight = getRequiredCellHeight(cellText, columnWidth);
            if (cellHeight > row.height) {
                row.height = cellHeight;
            }
        });
    });
    const excelFile = await workbook.xlsx.writeBuffer();
    const blob = new Blob([excelFile], { type: "application/octet-stream" });
    saveAs(blob, `${description?.mainHeading}.xlsx`);
};