import { apiUrl } from '../utils/constants';
import { DELETE, GET, POST, PUT } from './api.service.wrapper';
import { GetAuthUser, GetUserRole } from "../services/auth/auth.service"

const user = GetAuthUser();
let role = GetUserRole();

// export const GetProfile = async () => {
//     let response = await GET(apiUrl.profile);
//     return response;
// }
//
// export const UpdateProfile = async (id, data) => {
//     const config = {
//         headers: {
//             'content-type': 'multipart/form-data'
//         }
//     }
//     const response = await PUT(apiUrl.update_profile, id, data, config);
//     return response;
// }

export const GetUsers = async (keyword = '', page = '', perPage = '', is_paginate = 1,status,value) => {
    let params = {};
    if (page) {
        params['page'] = page;
    }

    if (perPage) {
        params['limit'] = perPage;
    }


    if (keyword) {
        params['query'] = keyword;
    }

    if (status) {
        params['isApproved'] = status === '1' ? true : false;
    }

    // params['is_paginate'] = is_paginate;
    const response = await GET(apiUrl.users + '/list', params);
    return response;
}

export const GetUserById = async (id) => {
    let response = '';
    response = await GET(apiUrl.users + '/' + id);
    return response;
}

export const CreateUser = async (data) => {
    const response = await POST(apiUrl.users + '/create', data);
    return response;
}

export const UpdateUser = async (id, data) => {
    const response = await PUT(apiUrl.users + '/edit', id, data);
    return response;
}

export const DeleteUser = async (id) => {
    const response = await DELETE(apiUrl.users + '/delete', id);
    return response;
}
