import React, { useState } from "react";
import { Form, Label, Button } from "react-bootstrap";
import { Logo } from "../../utils/Svg";
import { useForm } from "react-hook-form";
import { useHistory, withRouter } from "react-router";
import {
  EmptyLocalStorage,
  ForgetPasswordApi,
} from "../../services/auth/auth.service";
import { toast } from "react-toastify";
import { FadeLoader } from "react-spinners";

function ForgotPassword() {
  const history = useHistory();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  const [loader, setLoader] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const onSubmit = async (data) => {
    const email = data.email;
    setLoader(true);
    await ForgetPasswordApi(email)
      .then((data) => {
        setTimeout(() => setLoader(false), 3000);

        if (!data.error) {
          toast.success(data.msg);
          setLoader(false)
          history.push("/verify-code", { email: email });
        } else {
          setLoader(false)
          toast.error(data.msg);
        }

        setTimeout(() => setDisabled(true), 3000);
      })
      .catch((error) => {
        setLoader(false)
        setTimeout(() => setLoader(false), 3000);

        if (error.response.status === 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          toast.error(error.response.data.msg);
        }
      });
  };
  return (
    <div className={"text-center authLayout"}>
      <Form className={"form-signin"} onSubmit={handleSubmit(onSubmit)}>
        <a href="/">
          <Logo />
        </a>
        <h1 className="h5 mb-3 font-weight-normal ">
          Forgot Password
        </h1>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Control
            placeholder="Enter email"
            {...register("email", {
              required: {
                value: "required",
                message: "Email is required",
              },
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Entered value does not match email format",
              },
              maxLength: {
                value: 255,
                message: "Max length is 255",
              },
            })}
            type="email"
          />
          {errors.email && (
            <span className={"validation-error pr-5"} role="alert">
              {errors.email.message}
            </span>
          )}
        </Form.Group>
        <div className="d-grid gap-2 loader_custom">
          {loader ? (
            <div className="spin-loader">
              {" "}
              <FadeLoader color={' rgb(139 77 198)'} height={10} />
            </div>
          ) : (
            <Button
              variant="primary"
              size="lg"
              type="submit"
              className={"btn-green-theme"}
              disabled={disabled}
            >
              Request Verification Code
            </Button>
          )}
        </div>
        <p className="mt-5 mb-3 text-muted">
          &copy; All rights reserved by Tekrevol {new Date().getFullYear()}
        </p>
      </Form>
    </div>
  );
}

export default withRouter(ForgotPassword);
