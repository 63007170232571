import S3 from "react-aws-s3";
import { apiUrl, constants } from "./constants";
import { SetS3BaseUrl } from "../services/api.service.wrapper";
import moment from "moment";
window.Buffer = window.Buffer || require("buffer").Buffer;

export const MoneyFormat = (value) => {
  let currency = Intl.NumberFormat(constants.LOCALE, {
    style: "currency",
    currency: constants.CURRENCY,
    useGrouping: false,
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

  return currency.format(value);
};

export const randomIdWithName = (userName) => {
  let randomNumber = Math.random().nextInt(100000);
  return userName + randomNumber;
};



// This need to be deleted
export const UploadImageToS3 = async (fileInput) => {
  const file = fileInput;
  const newFileName = file.name;
  const fileSize = file.size;

  //Check filesize
  if (fileSize > parseInt(constants.S3CREDENTIAL.fileSize, 10)) {
    return {
      success: false,
      message: constants.ERRORMESSAGES.fileSize,
    };
  }

  const config = {
    bucketName: constants.S3CREDENTIAL.bucketName,
    dirName: constants.S3CREDENTIAL.dirName,
    region: constants.S3CREDENTIAL.region,
    accessKeyId: constants.S3CREDENTIAL.accessKeyId,
    secretAccessKey: constants.S3CREDENTIAL.secretAccessKey,
    s3Url: constants.S3CREDENTIAL.s3EndPoint,
  };

  const ReactS3Client = new S3(config);
  let responseData = {};
  try {
    await ReactS3Client.uploadFile(file, newFileName).then((data) => {
      if (data && data.status === 204) {
        responseData = {
          success: true,
          message: constants.ERRORMESSAGES.fileSuccess,
          data: data.location,
        };
      } else {
        responseData = {
          success: false,
          message: constants.ERRORMESSAGES.fileError,
        };
      }
    });
  } catch (err) {
    responseData = {
      success: false,
      message: constants.ERRORMESSAGES.fileError,
      error: err.message,
    };
  }
  return responseData;
};

// export async function uploadToS3(
//   fileType,
//   fileName,
//   fileContents,
//   folder_name = "other"
// ) {
//   try {
//     const body = {
//       contentType: fileType,
//     };

//     const config = {
//       headers: {
//         "x-access-token": constants.S3CREDENTIAL.app_token,
//         "Access-Control-Allow-Origin": "*",
//       },
//     };

//     //Here we are posting request with payload file type to get one time key access for uploading file
//     const presignedPostUrlResponse = await fetch(
//       SetS3BaseUrl() + apiUrl.get_s3_token,
//       {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           ...config.headers,
//         },
//         body: JSON.stringify(body),
//       }
//     );

//     //In case our request get failed

//     if (!presignedPostUrlResponse.ok) {
//       throw new Error("Failed to get presigned URL");
//     }

//     //Here from previous post response separating key and url and saving it in var for future use
//     const presignedPostUrl = await presignedPostUrlResponse.json();
//     let key;
//     let url;
//     if (presignedPostUrl?.data?.result?.fields?.Key) {
//       key = presignedPostUrl?.data?.result?.fields?.Key;
//     }
//     if (presignedPostUrl?.data?.url) {
//       url = presignedPostUrl?.data?.url;
//     }

//     //Here appending fields from previous response in next request payload
//     const formData = new FormData();
//     Object.entries(presignedPostUrl?.data?.result?.fields).forEach(([k, v]) => {
//       formData.append(k, v);
//     });
//     //Here appending file which we have to upload to S3
//     formData.append("file", fileContents); // The file has to be the last element
//     const response = await fetch(presignedPostUrl?.data?.result?.url, {
//       method: "POST",
//       headers: {
//         "Access-Control-Allow-Origin": "*",
//       },
//       body: formData,
//     });

//     //After successfull upload it will gonna return empty response just with status 200 or 204
//     //In case above condition return previously saved URL in var or return dynamic url which is comment below
//     if (response.status == 200 || response.status == 204) {
//       //For sending dynamic URL as a response uncomment below line of code and remove next return line
//       //below URL include S3 endpoint and token and previously saved key
//       // return constants.S3CREDENTIAL.s3EndPoint + apiUrl.get_s3_token +'/'+ key;
//       return url;
//     }
//     return false;
//   } catch (error) {
//     console.error(error);
//     return false;
//   }
// }

export async function uploadToS3(
  fileType,
  fileName,
  fileContents,
  folder_name = "other"
) {
  try {
    const body = {
      contentType: fileType,
    };

    const config = {
      headers: {
        "x-access-token": constants.S3CREDENTIAL.app_token,
        "Access-Control-Allow-Origin": "*",
      },
    };

    //Here we are posting request with payload file type to get one time key access for uploading file
    const presignedPostUrlResponse = await fetch(
      SetS3BaseUrl() + apiUrl.get_s3_token,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...config.headers,
        },
        body: JSON.stringify(body),
      }
    );

    //In case our request get failed

    if (!presignedPostUrlResponse.ok) {
      throw new Error("Failed to get presigned URL");
    }

    //Here from previous post response separating key and url and saving it in var for future use
    const presignedPostUrl = await presignedPostUrlResponse.json();
    let key;
    let url;
    if (presignedPostUrl?.data?.result?.fields?.Key) {
      key = presignedPostUrl?.data?.result?.fields?.Key;
    }
    if (presignedPostUrl?.data?.url) {
      url = presignedPostUrl?.data?.url;
    }

    //Here appending fields from previous response in next request payload
    const formData = new FormData();
    Object.entries(presignedPostUrl?.data?.result?.fields).forEach(([k, v]) => {
      formData.append(k, v);
    });
    //Here appending file which we have to upload to S3
    formData.append("file", fileContents); // The file has to be the last element
    const response = await fetch(presignedPostUrl?.data?.result?.url, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
      body: formData,
    });

    //After successfull upload it will gonna return empty response just with status 200 or 204
    //In case above condition return previously saved URL in var or return dynamic url which is comment below
    if (response.status == 200 || response.status == 204) {
      //For sending dynamic URL as a response uncomment below line of code and remove next return line
      //below URL include S3 endpoint and token and previously saved key
      // return constants.S3CREDENTIAL.s3EndPoint + apiUrl.get_s3_token +'/'+ key;
      return url;
    }
    return false;
  } catch (error) {
    console.error(error);
    return false;
  }
}
export const colourStyles = {
  control: (styles,state) => ({ ...styles,
    backgroundColor: '#1c2222',
    color: state.isDisabled ? '#6c757d' : '#FFFFFF',
    border: state.isFocused ? '1px solid #8b4dc6' : 0, // Set border width and color
    borderColor: state.isFocused ? '#1c2222' : '#8b4dc6',
    // This line disable the blue border
    boxShadow: state.isFocused ? '0 0 0 0.25rem rgba(139, 77, 198, 0.25)' : 'none',
    '&:hover': {
      border: state.isFocused ? '1px solid #8b4dc6' : '1px solid #8b4dc6', // Set border width and color on hover
      borderColor: state.isFocused ? '#1c2222' : '#8b4dc6',
    }
  }),
  placeholder: (styles,state) => ({ ...styles,
    color: state.isSelected ? '#FFFFFF' : '#6c757d',
  }),
  input: (styles,state) => ({ ...styles,
    color:  state.isDisabled ? '#6c757d' : '#FFFFFF',
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: '#8b4dc6',
    ':hover': {
      backgroundColor: '#8b4dc6',
      color: 'white',
    },
  }),
  singleValue: (styles,state) => ({ ...styles,
    color:  state.isDisabled ? '#c4c5c5' : '#FFFFFF',
  }),
  menu: (styles, { data, isDisabled, isFocused, isSelected,isActive }) => {
    return {
      ...styles,
      borderColor:'#1c2222' ,
      backgroundColor: '#1c2222',
    };
  },
  option: (styles, { data, isDisabled, isFocused, isSelected,isHovered }) => {
    return {
      ...styles,
      color: '#FFFFFF',
      borderColor:isFocused ? '1c2222' : '#1c2222' ,

      backgroundColor: isSelected ? '#8b4dc6':'#1c2222',
      cursor: isDisabled ? 'not-allowed' : 'default',
      ':hover': {
        backgroundColor: '#8b4dc6',
        color: 'white',
        cursor: 'pointer',
      },
    };
  },
};

export function convertTo24HourFormat(timeString) {
  const utcDate = new Date(timeString);

  // Convert UTC time to local time

  const dateTime = moment(utcDate).format('hh:mm A');


  return dateTime;

}

