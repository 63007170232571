import React, { useState, useEffect, } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Col,
  Row,
  Spinner,
} from "react-bootstrap";
import { AddIconOutline } from "../../components/SvgIcons";
import PackageTableComponent from "./PackageTableComponent";
import SubscribeTableComponent from "./SubscribeTableComponent";
import { BarLoader } from "react-spinners";

function PackageAndSubscription() {
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <div className={"usersPage"}>
      <h1 className="page-heading">Packages and Subscription</h1>
      {/* <div className={"customOrderFilters"}>
        <Row>
          <Col xs={12} sm={12} md={5} lg={5} xl={5}>
            <SearchBar onSearch={(value) => debouncedRequest(value)} />
          </Col>
          <Col
            xs={12}
            sm={12}
            md={{ span: 2, offset: 2 }}
            lg={{ span: 2, offset: 2 }}
            xl={{ span: 2, offset: 2 }}
          ></Col>
          <Col xs={12} sm={12} md={3} lg={3} xl={3}>
            <Link to="/packages-and-subscription/add-package">
              <Button className={"btn btn-green-theme h40 w-100"}>
                <AddIconOutline /> Add Packages
              </Button>
            </Link>
          </Col>
        </Row>
      </div> */}


      <div className={`${loading ? "opacity-100" : "opacity-0 d-none"} ${"barLoader w-100 d-flex justify-content-center"}`}>
        <BarLoader
          color={"#8b4dc6"}
          loading={loading}
          css={{ marginTop: "10px" }}
          height={"4"}
          width={"100%"}
        />
      </div>

      <div className={`${loading ? "opacity-0" : "opacity-100"} ${"mt-3"}`} >
        <PackageTableComponent />

        {/* =================== Subscribe Users =============== */}
        <SubscribeTableComponent />
      </div>
      {/* <div className="theme-dataGrid products-dataGrid">
            <h4>Subscribed Users</h4>
            <DataTable
              columns={subscribeUsersColumns}
              data={setsubscribeUserData}
              progressPending={loading}
              customStyles={customStyles}
              selectableRows={false}
              striped
              highlightOnHover
              pagination
              paginationServer
              paginationTotalRows={totalSubscribeRows}
              paginationPerPage={perPage}
              onChangePage={(page) => {
                setPage(page);
              }}
              onChangeRowsPerPage={(currentRowsPerPage) => {
                setPerPage(currentRowsPerPage);
              }}
              progressComponent={
                <BarLoader
                  color={"#ff00e6"}
                  loading={loading}
                  css={"marginTop: 10px"}
                  height={"4"}
                  width={"100%"}
                />
              }
            />
          </div> */}

    </div>
  );
}

export default PackageAndSubscription;
