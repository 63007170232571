import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
    Button,
    Col,
    Row,
    Tab,
    Tabs,
    Form,
} from "react-bootstrap";
import { toast } from "react-toastify";
import debounce from "lodash.debounce";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import { AddIconOutline } from "../../components/SvgIcons";
import SearchBar from "../../components/SearchBar";
import RegisterUserTable from "./RegisterUsersTable";
import {GetBusinessReports, GetReports, GetUserReports} from "../../services/reports.service";
import { exportToExcel } from "../../utils/excelFileDownload";
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { BarLoader } from "react-spinners";
import DataTable, {createTheme} from "react-data-table-component";
import {STATUS} from "../../utils/constants";

function StatsAndAnalytics() {
    const history = useHistory();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [key, setKey] = useState(location.state ? location.state.type : "1");
    const [rows, setRows] = useState([]);
    const [totalRows, setTotalRows] = useState();
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [searchTerm, setSearchTerm] = useState("");
    const maxYear = new Date().getFullYear();
    createTheme('solarized', {
        text: {
            primary: '#ffffff',
            secondary: '#929399',
        },
        background: {
            default: '#0d0f0f',
        },
        context: {
            background: '#0d0f0f',
            text: '#929399',
        },
        divider: {
            default: '#929399',
        },
        highlightOnHover: {
            default: 'rgb(139 77 198)',
            text: '#ffffff',
        },
        action: {
            button: 'rgba(0,0,0,.54)',
            hover: 'rgba(0,0,0,.08)',
            disabled: 'rgba(0,0,0,.12)',
        },
    }, 'dark');

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    const isEndDateValid = () => startDate && endDate && endDate >= startDate;

    const handleResetFunction = () => {
        setStartDate(new Date());
        setEndDate(new Date());
        setSearchTerm("");
    };

    const formatStartDate = startDate ? moment(startDate).format('YYYY-MM-DD') : '';
    const formatEndDate = endDate ? moment(endDate).format('YYYY-MM-DD') : '';


    let Sparams = {
        key,
        searchTerm,
        formatStartDate,
        formatEndDate
    }
    const handleDatePickerClose = () => {
        init(Sparams);
    };



    const init = async (Sparams) => {

        setLoading(true);
        setKey(Sparams.key);
        try {

            const reportParams = {
                query: Sparams?.searchTerm,
                paginate: true,
                page,
                limit:perPage,
                ...(Sparams?.formatStartDate ? {startDate: Sparams?.formatStartDate} : null),
                ...(Sparams?.formatEndDate ? {endDate: Sparams?.formatEndDate} : null),
            }
            let result;
             if(key === '1'){
                 result = await GetUserReports(reportParams);
             }else{
                 result = await GetBusinessReports(reportParams);
             }


            if (result.success) {
                setTotalRows(result.pagination?.total);
                let page = result.pagination.page-1
                let counter = (page*result.pagination.limit)+1;
                if(key === '1'){
                    const rowData = result?.users?.map((dt, index) => ({
                        sno: counter++,
                        id:dt._id || '-',
                        name: dt.username || '-',
                        email: dt.email || '-',
                        status: STATUS[dt.isApproved] || '-',

                    }));
                    setRows(rowData);
                }else{
                    const rowData = result?.business?.map((dt, index) => ({
                        sno: counter++,
                        id:dt._id || '-',
                        businessName: dt.bussinessName || '-',
                        businessStatus: dt.bussinessStatus || '-',
                        businessCategory: dt.bussinessCategory || '-',

                    }));
                    setRows(rowData);
                }

                setLoading(false);
            } else {
                setLoading(false);
                toast.error('in else');
            }

        } catch (error) {
            console.error("Error:", error);
            setLoading(false);
            if (error.response && error.response.status === 401) {
                EmptyLocalStorage();
                history.push("/");
            } else {
                toast.error(
                    error.response ? error.response.data.message : "An error occurred"
                );
            }
        }
    }

    useEffect(() => {
        init(Sparams);
    }, [endDate, page, perPage, key]);



    const columnRenderer = (key) => {
        const columns = [
            {
                name: "S.No",
                maxWidth: "60px",
                selector: "sno",
                center: true,
                sortable: true,
            },
            {
                name: "Name",
                selector: "name",
                maxWidth: "200px",
                center: false,
                sortable: true,
            },
            {
                name: "Email",
                selector: "email",
                maxWidth: "200px",
                center: false,
                sortable: true,
            },
        ];
        const columnsBusiness = [
            {
                id: 'id',
                name: 'Id',
                selector: "sno",
                sortable: true,
            },
            {
                id: 'businessName',
                name: 'Business Name',
                sortable: true,
                selector: "businessName",
            },
            {
                id: 'businessStatus',
                name: 'Business Status',
                grow: false,
                center: true,
                sortable: true,
                selector: "businessStatus",
            },
            {
                id: 'businessCategory',
                name: 'Business Category',
                grow: false,
                center: true,
                sortable: true,
                selector: "businessCategory",
            }
        ];

        return key == '1' ? columns : columnsBusiness;
    };

    const handleExcelDownload = async () => {
        if (rows?.length > 0) {
            let rowData;
            const reportParams = {
                query: Sparams?.searchTerm,
                paginate: false,
                page,
                limit:perPage,
                ...(Sparams?.formatStartDate ? {startDate: Sparams?.formatStartDate} : null),
                ...(Sparams?.formatEndDate ? {endDate: Sparams?.formatEndDate} : null),
            }
            let result;
            if(key === '1'){
                result = await GetUserReports(reportParams);
            }else{
                result = await GetBusinessReports(reportParams);
            }

            if (result.success) {
                if(key === '1'){
                     rowData = result?.users?.map((dt, index) => ({
                        sno: index + 1,
                        id:dt._id || '-',
                        name: dt.username || '-',
                        email: dt.email || '-',
                        status: STATUS[dt.isApproved] || '-',

                    }));
                }else{
                       rowData = result?.business?.map((dt, index) => ({
                        sno: index + 1,
                        id:dt._id || '-',
                        businessName: dt.bussinessName || '-',
                        businessStatus: dt.bussinessStatus || '-',
                        businessCategory: dt.bussinessCategory || '-',

                    }));
                }
            } else {
                toast.error('Error in exporting data');
            }

            exportToExcel(rowData, columnRenderer(key), {
                mainHeading: "Statistics & Analytics Report",
                subHeading: `Following is data report for ${ key === "1"
                        ? "Users"
                        : "Businesses"
                    }`,
            });
        } else {
            toast.warning("No data found")
        }

    };

    const debouncedRequest = debounce(
        (searchTerm) => {
            Sparams.searchTerm = searchTerm;
            init(Sparams)
        },
        500
    );
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            border: '1px solid #c4c4c4',
            borderRadius: '2px',
            boxShadow: state.isFocused ? '0 0 0 1px #ff00e6' : null,
            fontSize: window.innerWidth >= 1366 ? '13px' : '14px',
            height: '40px',
            '&:hover': {
                border: '1px solid #ff00e6',
                cursor: 'pointer'
            },
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? 'rgb(139, 77, 198)' : state.isHovered ? '#ffe6ff' : null,
            color: state.isSelected ? 'white' : 'black',
            '&:hover': {
                backgroundColor: 'rgb(139, 77, 198)',
                color: 'white',
                cursor: 'pointer'
            },
        }),
    };

    const handleTabSelect = (selectedKey) => {
        setSearchTerm("");

        if (selectedKey === "2") {
            setKey(selectedKey);
        } else {
            setKey('1');
        }
    };
    return (
        <div className={"usersPage"}>
            <h1 className="page-heading">Statistics and Analytics</h1>
            <div className={"customOrderFilters mb-3"}>
                <Row className="g-1">
                    <Col xs={12} sm={12} md={4} lg={3} xl={3} xxl={3} className="d-flex align-items-end">
                        <SearchBar
                            noMargin={true}
                            onSearch={(value) => debouncedRequest(value)}
                        />
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={{span:2,offset:1}} xl={{span:2,offset:1}} xxl={{span:2,offset:2}}>
                        <div className="datepickerBox">
                            <DatePicker
                                selected={startDate}
                                onChange={handleStartDateChange}
                                dateFormat="dd-MM-yyyy"
                                maxDate={new Date(maxYear, 11, 31)}
                                placeholderText="Select Start Date"
                            />
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={2} xl={2} xxl={2}>
                        <div className="datepickerBox">
                            <DatePicker
                                selected={endDate}
                                onChange={handleEndDateChange}
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate}
                                maxDate={new Date(maxYear, 11, 31)}
                                dateFormat="dd-MM-yyyy"
                                placeholderText="Select End Date"
                                onClose={handleDatePickerClose}
                            />
                            {!isEndDateValid() && (
                                <></>
                            )}
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={2} lg={1} xl={1} xxl={1}>
                        <Button onClick={handleResetFunction} className="transparentBtn h40">
                            Reset
                        </Button>
                    </Col>
                    <Col xs={12} sm={12} md={4} lg={3} xl={3} xxl={2}>
                        <div className="d-flex justify-content-end align-items-end h-100 reportBtn-wrapper">
                            <Button
                                onClick={handleExcelDownload}
                                className={"btn btn-green-theme h40 reportBtn"}
                            >
                                <AddIconOutline /> Download Report
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="tabs-wrapper position-relative">
                <Tabs
                    id="statsAndAnalyticsTabs"
                    activeKey={key}
                    onSelect={handleTabSelect}
                >
                    <Tab eventKey="1" title="Users">
                        <DataTable
                            title=""
                            columns={columnRenderer(key)}
                            data={rows}
                            progressPending={loading}
                            customStyles={customStyles}
                            selectableRows={false}
                            // striped
                            theme='solarized'
                            highlightOnHover
                            pagination
                            paginationServer
                            paginationTotalRows={totalRows}
                            paginationPerPage={perPage}

                            onChangePage={(page) => {
                                setPage(page);

                            }}
                            onChangeRowsPerPage={(currentRowsPerPage) => {
                                setPerPage(currentRowsPerPage);
                            }}
                            progressComponent={
                                <BarLoader
                                    color={"#8b4dc6"}
                                    loading={loading}
                                    css={{ marginTop: "10px" }}
                                    height={"4"}
                                    width={"100%"}
                                />
                            } />
                    </Tab>
                    <Tab eventKey="2" title="Businesses">
                        <RegisterUserTable
                            theme='solarized'
                            columns={columnRenderer(key)}
                            rows={rows}
                            totalRows={totalRows}
                            page={page}
                            setPage={setPage}
                            loading={loading}
                            setPerPage={setPerPage}
                            perPage={perPage}
                        />
                    </Tab>
                </Tabs>
            </div>
        </div>
    );
}

export default StatsAndAnalytics;
