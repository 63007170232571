import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import AuthLayoutRoute from "./layouts/AuthLayout";
import Signin from "./views/auth/Signin";
import DashboardLayoutRoute from "./layouts/DashboardLayout";
import Dashboard from "./views/dashboard/Dashboard";
import ChangePassword from "./views/auth/ChangePassword";
import { ToastContainer } from "react-toastify";
import About from "./views/PrivacyPolicy&T&C/About";
import ResetPassword from "./views/auth/ResetPassword";
import ForgotPassword from "./views/auth/ForgotPassword";
import VerifyCode from "./views/auth/VerifyCode";
import PushNotification from "./views/pushNotification/PushNotification";
import PackageAndSubscription from "./views/packageAndSubscription/PackageAndSubscription";
import AddPackage from "./views/packageAndSubscription/AddPackage";
import FaqsPage from "./views/faqs/FaqsPage";
import AddFaqs from "./views/faqs/AddFaqs";
import EditFaqs from "./views/faqs/EditFaqs";
import StatsAndAnalytics from "./views/statisticsAnalytics/StatsAndAnalytics.js";
import EditPackage from "./views/packageAndSubscription/EditPackage.js";
import Pages from "./views/pages/Pages";
import EditPage from "./views/pages/EditPage";
import AddPage from "./views/pages/AddPage";
import Users from "./views/users/Users";
import AddNewUser from "./views/users/AddNewUser";
import EditUser from "./views/users/EditUser";
import Business from "./views/business/Business";
import PreviewBusiness from "./views/business/PreviewBusiness";
import AddBusiness from "./views/business/AddBusiness";
import UserView from "./views/users/UserView";
import ConnectAccountFailure from "./views/Stripe/ConnectAccountFailure";
import ConnectAccount from "./views/Stripe/ConnectAccount";
import Payment from "./views/payment/Payment";
import EditComissionPercent from "./views/payment/EditComissionPercent";

function App() {
  return (
    <Router>
      <Switch>
        {/**
         *
         *  Define all the page routes here
         *
         */}
        <Route exact isAuth={true} path="/pages/:slug" component={About} />
        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/change-password`}
          component={ChangePassword}
        />

        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/dashboard`}
          component={Dashboard}
        />
        {/* ================= Users Management ============================ */}

        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/users`}
          component={Users}
        />
        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/users/add-user`}
          component={AddNewUser}
        />
        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/users/edit-user/:id`}
          component={EditUser}
        />
        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/users/view-user/:id`}
          component={UserView}
        />
        {/* ================= Business Management ============================ */}

        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/businesses`}
          component={Business}
        />
        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/businesses/add-business`}
          component={AddBusiness}
        />
        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/businesses/edit-business/:id`}
          component={PreviewBusiness}
        />
        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/businesses/preview/:id`}
          component={PreviewBusiness}
        />
        {/* ================= Packages and Subscription ============================ */}
        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/packages-and-subscription`}
          component={PackageAndSubscription}
        />
        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/packages-and-subscription/add-package`}
          component={AddPackage}
        />
        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/packages-and-subscription/edit-package/:id`}
          component={EditPackage}
        />

        {/* ================= Statistics and Analytics ============================ */}

        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/statistics-and-analytics`}
          component={StatsAndAnalytics}
        />
        {/* ================= Payment ============================ */}
        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/payments`}
          component={Payment}
        />
        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/carete-user-and-bussines`}
          component={StatsAndAnalytics}
        />

        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/settings/edit-commission-percentage`}
          component={EditComissionPercent}
        />

        {/**
         *
         *  Pages routes
         *
         */}

        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/pages`}
          component={Pages}
        />

        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/pages/edit-page/:id`}
          component={EditPage}
        />

        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/add-page`}
          component={AddPage}
        />

        {/**
         *
         *  Push Notification
         *
         */}

        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/push-notification`}
          component={PushNotification}
        />

        {/**
         *
         *  Frequently Ask Question
         *
         */}

        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/faqs`}
          component={FaqsPage}
        />
        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/faqs/add-faqs`}
          component={AddFaqs}
        />
        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/faqs/edit-faqs/:id`}
          component={EditFaqs}
        />

        {/**
         *
         *  Authentication routes
         *
         */}
        <AuthLayoutRoute
          exact
          isAuth={true}
          path="/forgot-password"
          component={ForgotPassword}
        />
        <AuthLayoutRoute
          exact
          isAuth={false}
          path="/verify-code"
          component={VerifyCode}
        />
        <AuthLayoutRoute
          exact
          isAuth={true}
          path="/reset-password"
          component={ResetPassword}
        />
        <AuthLayoutRoute
          isAuth={true}
          path="/return-connect-account-failure"
          component={ConnectAccountFailure}
        />
        <AuthLayoutRoute
          isAuth={true}
          path="/return-connect-account"
          component={ConnectAccount}
        />
        <AuthLayoutRoute isAuth={true} path="/" component={Signin} />

        {/**
         *
         *  Error routes
         *
         */}
      </Switch>
      <ToastContainer hideProgressBar theme="dark" autoClose={2000} />
    </Router>
  );
}

export default App;
