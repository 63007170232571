import { apiUrl } from '../utils/constants';
import {DELETE, DELETEMOB, GET, POST, PUT, PUTMOB} from './api.service.wrapper';
import { GetAuthUser, GetUserRole } from "../services/auth/auth.service"

const user = GetAuthUser();
let role = GetUserRole();

// export const GetProfile = async () => {
//     let response = await GET(apiUrl.profile);
//     return response;
// }
//
// export const UpdateProfile = async (id, data) => {
//     const config = {
//         headers: {
//             'content-type': 'multipart/form-data'
//         }
//     }
//     const response = await PUT(apiUrl.update_profile, id, data, config);
//     return response;
// }

export const GetBusiness = async (keyword = '', page = '', perPage = '', is_paginate = 1,businessStatus,businessCategory,searchoption ='bussinessName') => {
    let params = {};
    if (page) {
        params['page'] = page;
    }

    if (perPage) {
        params['limit'] = perPage;
    }


    if (keyword) {
        params[searchoption] = keyword;
    }

    if (businessStatus) {
        params['businessStatus'] = businessStatus;
    }
    if (businessCategory) {
        params['businessCategory'] = businessCategory;
    }

    const response = await GET(apiUrl.business +'/list', params);
    return response;
}

export const GetBusinessById = async (id) => {
    let response = '';
    response = await GET(apiUrl.business + '/' + id);
    return response;
}

export const UpdateBusinessStatus = async ( data) => {
    const response = await PUT(apiUrl.business , '/update-status', data);
    return response;
}

export const UpdateBusinessLocation = async ( data) => {
    const response = await PUT(apiUrl.business , '/update-location', data);
    return response;
}
export const CreateBusiness = async (data) => {
    const response = await POST(apiUrl.business, data);
    return response;
}

export const UpdateBusiness = async (id, data) => {
    const response = await PUTMOB(apiUrl.updateBusiness, id, data);
    return response;
}

export const DeleteBusiness = async (id) => {
    const response = await DELETE(apiUrl.business ,id );
    return response;
}

