import React, { useRef, useState } from 'react'
import { Form, Row, Col, InputGroup, FormControl, Button } from 'react-bootstrap'
import { useForm } from "react-hook-form";
import {ChangePasswordApi, EmptyLocalStorage, GetAuthUser} from "../../services/auth/auth.service"
import { toast } from "react-toastify";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useHistory } from 'react-router-dom';
import { FadeLoader } from "react-spinners";
import {constants} from "../../utils/constants";

function ChangePassword() {
    const history = useHistory();
    const [pass, TogglePass] = useState(true);
    const [newPass, ToggleNewPass] = useState(true);
    const [confirmPass, setConfirmPass] = useState(true);
    const [loader, setLoader] = useState(false);

    const { register, handleSubmit, watch, reset, setError, clearErrors, formState: { errors } } = useForm({
        mode: "onChange"
    });
    const [disableButton, setDisableButton] = useState(false);
    const password = useRef({});
    password.current = watch("NewPassword", "");
    const onSubmit = async data => {
        setLoader(true)
        let userId = JSON.parse(localStorage.getItem(constants.LOCAL_STORAGE_USER))?.userId
        let currentPassword = data.password
        let newPassword = data.NewPassword
        let confirmPassword = data.NewConfirmPassword
        if (newPassword == currentPassword) {
            setError('NewPassword', { message: 'New password cannot be the same as your old password' });
            setLoader(false)
            return false
        } else (
            clearErrors('NewPassword')
        )
            let Postdata ={
                userId,
                currentPassword,
                newPassword,
                confirmPassword
            }
        await ChangePasswordApi(Postdata).then((data) => {
            if (data.status) {
                toast.success(data.message);
                reset();
                setLoader(false)
            }
            else {
                setDisableButton(false)
                toast.error("test", data.message);
                setLoader(false)
            }
        }).catch((error) => {
            setDisableButton(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                toast.error(error.response.data.message);
                setLoader(false);
            }
        })

    };

    return (
        <div className="AddNewUser">
            {/*<BreadcrumbsComponent />*/}
            <h3 className="page-heading">Change Password</h3>
            <Row>
                <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                    <Form className={"form-add-user"} onSubmit={handleSubmit(onSubmit)}>
                        <div className={"pass-field mb-3"}>
                            <Form.Label>Current Password</Form.Label>
                            <InputGroup controlId="CurrPassowrd">
                                <FormControl
                                    placeholder="Enter Password"
                                    autoComplete={"off"}
                                    {...register('password', {
                                        required: {
                                            value: "required",
                                            message: "Password is required"
                                        },
                                        minLength: {
                                            value: 6,
                                            message: "Min length is 8"
                                        },
                                        maxLength: {
                                            value: 255,
                                            message: "Max length is 255"
                                        }
                                    })}
                                    type={pass ? "password" : "text"} />
                                <Button variant="outline-secondary" id="button-addon2" onClick={() => TogglePass(!pass)}>
                                    {
                                        pass ? <FaEye /> : <FaEyeSlash />
                                    }
                                </Button>
                            </InputGroup>
                            {errors.password && <Form.Text className=" validationText hasError">{errors.password.message}</Form.Text>}
                        </div>

                        <div className={"pass-field mb-3"}>
                            <Form.Label>New Password</Form.Label>
                            <InputGroup controlId="NewPassword">
                                <FormControl
                                    placeholder="Enter New Password"
                                    autoComplete={"off"}
                                    {...register('NewPassword', {
                                        required: {
                                            value: "required",
                                            message: "New Password is required"
                                        },
                                        minLength: {
                                            value: 8,
                                            message: "Min length is 8"
                                        },
                                        maxLength: {
                                            value: 255,
                                            message: "Max length is 255"
                                        }
                                    })}
                                    type={newPass ? "password" : "text"} />
                                <Button variant="outline-secondary" id="button-addon2" onClick={() => ToggleNewPass(!newPass)}>
                                    {
                                        newPass ? <FaEye /> : <FaEyeSlash />
                                    }
                                </Button>
                            </InputGroup>
                            {errors.NewPassword && <Form.Text className=" validationText hasError">{errors.NewPassword.message}</Form.Text>}
                        </div>

                        <div className={"pass-field mb-3"}>
                            <Form.Label>Confirm Password</Form.Label>
                            <InputGroup controlId="newConfirmPassowrd">
                                <FormControl
                                    placeholder="Confirm Password"
                                    autoComplete={"off"}
                                    {...register('NewConfirmPassword', {
                                        required: {
                                            value: "required",
                                            message: "Confirm password is required"
                                        },
                                        validate: password.current ? value => value === password.current || "The passwords do not match" : '',
                                        minLength: {
                                            value: 8,
                                            message: "Min length is 8"
                                        },
                                        maxLength: {
                                            value: 255,
                                            message: "Max length is 255"
                                        }
                                    })}
                                    type={confirmPass ? "password" : "text"} />
                                <Button variant="outline-secondary" id="button-addon2" onClick={() => setConfirmPass(!confirmPass)}>
                                    {
                                        confirmPass ? <FaEye /> : <FaEyeSlash />
                                    }
                                </Button>
                            </InputGroup>
                            {errors.NewConfirmPassword && <Form.Text className=" validationText hasError">{errors.NewConfirmPassword.message}</Form.Text>}
                        </div>

                        <div className="d-grid gap-2 loader_custom">

                            {loader ? <div className="spin-loader"> <FadeLoader color={"rgb(139, 77, 198)"} height={10} /></div>
                                :
                                <Button variant="primary" size="lg" type="submit" className={"btn-green-theme text-uppercase"}>
                                    Change Password
                                </Button>
                            }
                        </div>
                    </Form>

                </Col>
            </Row>

        </div>
    )
}

export default ChangePassword
