import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import { FadeLoader } from "react-spinners";
import Select from "react-select";
import { AddPackages } from "../../services/packages.service";

const packagesData = [
  { label: "Advance", value: "advance" },
  { label: "Beginner", value: "beginner" },
];

function AddPackage() {
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [packages] = useState('')
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  const onSubmit = async (data) => {
    setLoader(true);
    try {
      const packageData = {
        name: data.package_name,
        price: parseFloat(data.price),
        description: data.description,
        best_value: 0,
        identifiers: "advance",
        is_active: 1,
      };


      const response = await AddPackages(packageData);

      if (response.status) {
        toast.success(response.message);
        history.push("/packages-and-subscription");
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      setLoader(false);
      console.error(error);

      if (error.response && error.response.status === 401) {
        EmptyLocalStorage();
        history.push("/");
      } else {
        toast.error(error.response?.data?.message || "An error occurred");
      }
    }
  };

  const validateBestValue = (value, allValues) => {
    const price = parseFloat(allValues.price);
    const bestValue = parseInt(value, 10);

    if (bestValue <= 0) {
      return "Best Value should be greater than 0.";
    }

    if (bestValue >= price) {
      return "Best Value should be less than the Price.";
    }

    return true;
  };

  return (
    <div className="AddNewUser AddTimeZone">
      <h3 className="page-heading">Add Packages</h3>
      <Row>
        <Col xs={12} sm={12} md={8} lg={6} xl={7}>
          <Form
            encType="multipart/form-data"
            className="formAddCatrgories"
            autoComplete="off"
            role="presentation"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row>
              <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                <Form.Group className="mb-3" controlId="package_name">
                  <Form.Label>
                    Package Name<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    placeholder="Enter Package Name"
                    maxLength={25}
                    minLength={3}
                    {...register("package_name", {
                      required: "Package Name is required",
                      minLength: { value: 3, message: "Min length is 3" },
                      maxLength: { value: 25, message: "Max length is 25" },
                    })}
                    type="text"
                  />
                  {errors.package_name && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.package_name.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                <Form.Group className="mb-3" controlId="price">
                  <Form.Label>
                    Price<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    placeholder="Enter Price"
                    {...register("price", {
                      required: "Price is required",
                      pattern: {
                        value: /^(?!0+(\.0*)?$)\d{1,8}(\.\d{1,2})?$/,
                        message: "Please enter a valid positive number with up to 8 digits and up to 2 decimal places for the price.",
                      },
                      validate: (value) => {
                        const numericValue = parseFloat(value);
                        if (isNaN(numericValue) || numericValue < 0) {
                          return "Price must be a non-negative number.";
                        }
                        return true;
                      },
                    })}
                  />
                  {errors.price && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.price.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>



              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Form.Group controlId="description" className="mb-3">
                  <Form.Label>Description*</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    placeholder="Description"
                    {...register("description", {
                      required: {
                        value: "required",
                        message: "Description is required",
                      },
                      minLength: {
                        value: 3,
                        message: "Min length is 3",
                      },
                      maxLength: {
                        value: 450,
                        message: "Max length is 450",
                      },
                    })}
                  />
                  {errors.description && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.description.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              {/* <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                <Form.Group className="mb-3" controlId="best_value">
                  <Form.Label>
                    Best Value<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    placeholder="Enter Best Value"
                    {...register("best_value", {
                      required: "Best Value is required",
                      validate: validateBestValue,
                    })}
                    type="text"
                  />
                  {errors.best_value && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.best_value.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} sm={6} md={6} lg={6} xl={6} >
                <Form.Group controlId="category_id">
                  <Form.Label>
                    Packages <small style={{ color: "red" }}>*</small>
                  </Form.Label>
                  <Controller
                    control={control}
                    value={packages}
                    name="packages"
                    rules={{
                      required: {
                        value: true,
                        message: `Packages is required`,
                      },
                      pattern: {
                        value: /^[^\s]+(?:$|.*[^\s]+$)/,
                        message: "Please enter valid value."
                      },
                    }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label={`Select packages`}
                        options={packagesData}
                        name="packages"
                      />
                    )}
                  />
                  {errors.packages && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.packages.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col> */}
              {/* <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                <Form.Label>
                  Activate Package <small style={{ color: "red" }}>*</small>
                </Form.Label>
                <Form.Group controlId="active">
                  <Form.Check
                    type="switch"
                    label=""
                    {...register("is_active")}
                  />
                </Form.Group>
              </Col> */}
            </Row>
            <Row>
              <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                {loader ? (
                  <div className="spin-loader">
                    <FadeLoader color={"#ff00e6"} height={10} />
                  </div>
                ) : (
                  <input
                    type={"submit"}
                    className={"btn btn-green-theme w-100 mt-3"}
                    value={"Add Packages"}
                  />
                )}
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default AddPackage;
