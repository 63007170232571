import React, { useState, useEffect } from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {GetUsers} from "../../services/users.service";
import {toast} from "react-toastify";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {GetPrivacyPolicyPage, GetTCPage} from "../../services/about.service";
import Header from "../../components/Header";
import {Container, Row} from "react-bootstrap";
import SideBar from "../../components/SideBar";
import {Interweave} from "interweave";

const About = () => {
    let history = useHistory();
    const { slug } = useParams();
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    useEffect(() => {
           getPrivacyPolicy()
    }, [slug]);

    const getPrivacyPolicy = async () => {
        await GetPrivacyPolicyPage(slug).then((result) => {
            if (result?.status) {
                if (result?.data) {
                        setTitle(result?.data.title)
                        setDescription(result?.data.content)
                }
            } else {

                return toast.error(result.message);
            }
        }).catch((error) => {

            if (error?.response?.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error?.response?.data?.message);
            }
        })
    }
    return (
        <>
            <Header url={"/"}/>
            <Container >
                <Row>
                    <main className={"col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"}>
                        <div className={"main_content_wrapper text-center "}>
                            <h1 className={"mt-2"}>{title}</h1>
                            <Interweave content={description} />
                        </div>
                    </main>
                </Row>
            </Container>


        </>
    );
};

export default About;
