import React, { useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { useForm } from "react-hook-form";
import { useHistory } from 'react-router-dom'
import { FadeLoader } from 'react-spinners';
import {SendNotification} from "../../services/notification.service";
import {toast} from "react-toastify";
import {EmptyLocalStorage} from "../../services/auth/auth.service";


// Update: API is pending of this module.


function PushNotification(props) {
    let history = useHistory();
    const [loader, setLoader] = useState(false);
    const { register, reset, setError, clearErrors, handleSubmit, formState: { errors } } = useForm({
        mode: "onBlur",
    });



    const onSubmit = async (data) => {
        setLoader(true)
        let dt = {
            title: data.title,
            body:data.description,
        }

        await SendNotification(dt).then(async (data) => {
            setLoader(false)
            if (!data.error) {
                toast.success("Notifications has been sent successfully");
                reset()
            } else {
                setLoader(false)
                toast.error(data.msg);
            }
        }).catch((error) => {
            setLoader(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    };


    return (
        <div className="AddNewUser">
            {/*<BreadcrumbsComponent />*/}
            <h3 className="page-heading">Push Notifications</h3>
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Form encType="multipart/form-data" className="formAddUser" autoComplete="off" role="presentation" onSubmit={handleSubmit(onSubmit)}>

                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Title<span className="text-danger">*</span></Form.Label>
                                    <Form.Control
                                        placeholder="Enter Title"
                                        id={"formSignUpName"}
                                        maxLength={25}
                                        minLength={3}
                                        {...register("title", {
                                            required: {
                                                value: "required",
                                                message: "Title is required",
                                            },
                                            pattern:{
                                                value:/^(?!\s).*$/,
                                                message:"Title should not start with a space"
                                            },
                                            minLength: {
                                                value: 3,
                                                message: "Min length is 3",
                                            },
                                            maxLength: {
                                                value: 25,
                                                message: "Max length is 25",
                                            },
                                        })}
                                        type="text"
                                    />
                                    {errors.title && (
                                        <Form.Text className="text-muted validationText hasError">
                                            {errors.title.message}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mb-3" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group controlId="description">
                                    <Form.Label>Description<span className="text-danger">*</span></Form.Label>
                                    <Form.Control as="textarea"
                                        placeholder="Add Description"
                                        rows={5}
                                        {...register('description', {
                                            required: {
                                                value: "required",
                                                message: "Description is required"
                                            },
                                            pattern:{
                                                value:/^(?!\s).*$/,
                                                message:"Description should not start with a space"
                                            },
                                            minLength: {
                                                value: 1,
                                                message: "Min length is 1"
                                            },
                                            maxLength: {
                                                value: 450,
                                                message: "Max length is 450"
                                            },
                                        })} />
                                    {errors.description && <Form.Text
                                        className="text-muted validationText hasError">{errors.description.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                {loader ? <div className="spin-loader">
                                    <FadeLoader color={"rgb(139, 77, 198)"} height={10} />
                                </div> :
                                    <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"}
                                        value={"Push"} />}
                            </Col>


                        </Row>
                    </Form>

                </Col>
            </Row>

        </div>
    )
}

export default PushNotification
