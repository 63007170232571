import React, { useState, useEffect, useCallback } from "react";
import { GetUsersReport } from "../../services/dashboard.service";
import { toast } from "react-toastify";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import debounce from "lodash.debounce";
import { useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
import { Col, Row } from "react-bootstrap";
import SearchBar from "../../components/SearchBar";
import { customStyles } from "../../utils/constants";
import { BarLoader } from "react-spinners";
import { GetReports } from "../../services/reports.service";

const SubscribeTableComponent = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [totalRows, setTotalRows] = useState(10);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);

    const init = useCallback(
        async (searchTerm) => {
            setLoading(true);
            try {

                const reportParams = {
                    is_premium: true,
                    keyword: searchTerm,
                    is_paginated: 1,
                    page,
                    perPage,
                }

                const response = await GetReports(reportParams);
                console.log(response, "response")
                setTotalRows(response?.data.users?.meta?.total);
                if (response.status) {
                    const rowData = response?.data?.users?.data?.map((dt, index) => ({
                        id: dt.id || "-",
                        username: dt.username || "-",
                        email: dt.email || "-",
                        phone: dt.phone || "-",
                        premiumUser: dt?.is_premium == 1 ? "Yes" : "No",
                    }));
                    setRows(rowData);
                    setLoading(false);
                } else {
                    setLoading(false);
                    toast.error(response.message);
                }
            } catch (error) {
                setLoading(false);

                if (error.response && error.response.status === 401) {
                    EmptyLocalStorage();
                    history.push("/");
                } else {
                    toast.error(
                        error.response ? error.response.data.message : "An error occurred"
                    );
                }
            }
        },
        [history, page, perPage]
    );

    useEffect(() => {
        init();
    }, [page, perPage]);

    //Debounce Search Input
    const debouncedRequest = debounce((searchTerm) => init(searchTerm), 500);

    const subscribeUsersColumns = [
        {
            name: "S.No",
            maxWidth: "60px",
            center: true,
            cell: (row, index) => <span>{index + 1}</span>,
        },
        {
            name: "username",
            selector: "username",
            maxWidth: "200px",
            center: false,
            sortable: true,
        },
        {
            name: "email",
            selector: "email",
            maxWidth: "200px",
            center: false,
            sortable: true,
        },
        {
            name: "Premium User",
            selector: "premiumUser",
            maxWidth: "200px",
            center: false,
            sortable: true,
        },
    ];

    return (
        <div className="packageTableComponent ">
            <div className="theme-dataGrid products-dataGrid">
                <Row>
                    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                        <h4>Subscribe Users</h4>
                    </Col>
                    <Col
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                        xl={6}
                        className="d-flex justify-content-end"
                    >
                        <SearchBar onSearch={(value) => debouncedRequest(value)} />
                    </Col>
                </Row>
                <div className="mt-3">
                    <DataTable
                        columns={subscribeUsersColumns}
                        data={rows}
                        progressPending={loading}
                        customStyles={customStyles}
                        selectableRows={false}
                        striped
                        highlightOnHover
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        paginationPerPage={perPage}

                        onChangePage={(page) => {
                            setPage(page);

                        }}
                        onChangeRowsPerPage={(currentRowsPerPage) => {
                            setPerPage(currentRowsPerPage);
                        }}
                        progressComponent={
                            <BarLoader
                                color={"#ff00e6"}
                                loading={loading}
                                css={"marginTop: 10px"}
                                height={"4"}
                                width={"100%"}
                            />
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default SubscribeTableComponent;
