import React, {useRef, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";
import {colourStyles, uploadToS3} from "../../utils/helpers";
import {toast} from "react-toastify";
import {CreateUser} from "../../services/users.service";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {Button, Col, Form, FormControl, InputGroup, Row} from "react-bootstrap";
import {FaEye, FaEyeSlash} from "react-icons/fa";
import {FadeLoader} from "react-spinners";
import {CrossIcon} from "../../utils/Svg";
import Select from "react-select";
import Autocomplete from "react-google-autocomplete";

function AddBusiness(props) {
    let history = useHistory();
    const [data, setData] = useState([]);
    const [pass, TogglePass] = useState(true);
    const [confPass, ToggleConfPass] = useState(true);
    const [loader, setLoader] = useState(false);
    const [selectedMusicType, setSelectedMusicType] = useState();
    const [selectedBusinessType, setSelectedBusinessType] = useState();
    const [selectedCapacity, setSelectedCapacity] = useState();
    const [selectedEntertainment, setSelectedEntertainment] = useState();
    const [businessTypeOption, setBusinessTypeOption] = useState([
        { value: "BOTH", label: "BOTH" },
        { value: "CLUB", label: "CLUB" },
        { value: "BAR", label: "BAR" },
    ]);
    const [musicOption, setMusicOption] = useState([
        { value: "EDM", label: "EDM" },
        { value: "HIP HOP", label: "HIP HOP" },
        { value: "RAP", label: "RAP" },
        { value: "POP", label: "POP" },
        { value: "ROCK", label: "ROCK" }
    ]);
    const [capacityOption, setCapacityOption] = useState([
        { value: "0-25", label: "0-25" },
        { value: "25-50", label: "25-50" },
        { value: "50-100", label: "50-100" },
        { value: "unlimited", label: "unlimited" },
    ]);
    const [entertainmentOption, setEntertainmentOption] = useState([
        { value: "EDM", label: "EDM" },
        { value: "HIP HOP", label: "HIP HOP" },
        { value: "RAP", label: "RAP" },
        { value: "POP", label: "POP" },
        { value: "ROCK", label: "ROCK" }
    ]);
    const [image, setImage] = useState([])
    const [file, setFile] = useState([])
    const [isUpload, setIsUpload] = useState(false)
    const [role, setRole] = useState([]);
    const { register, watch, setError, clearErrors, handleSubmit, formState: { errors } } = useForm({
        mode: "onBlur",
    });
    const [location, setLocation] = useState({
        lat:data?.data?.lat,
        long:data?.data?.long,
        place_id:data?.data?.place_id,
        formatted_address:data?.data?.address
    })
    const regex= new RegExp("^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$")
    const maxDate = new Date().toISOString().split('T')[0]; // Get today's date in ISO format


    const password = useRef({});
    password.current = watch("password", "");
    const onSubmit = async data => {

        if(file){
            clearErrors('media')
        }else{
            setError('media',{message:"Image is required"})
        }
        if(file.type.startsWith('image/')){
            clearErrors('media')
        }else{
            setError('media',{message:'Invalid file type'})
            return false;
        }
        setLoader(true)
        let uploadImage = await uploadToS3(file.type,file.name,file,'category');

        if (uploadImage) {
            if (!uploadImage) {
                setLoader(false)
                toast.error(uploadImage.message);
                return false;
            }
        } else {
            setLoader(false)
            toast.error('Can\'t Upload Image');
            return false;
        }
        data.media=uploadImage;
        await CreateUser({
            full_name: data.full_name,
            image:data.media,
            email: data.email,
            address: data.address,
            phone: data.phone || null,
            bio: data.bio || null,
            dob: data.dob,
            gender:data.gender,
            type:'User',
            password: data.password,
            password_confirmation: data.password_confirmation,
            device_type: 'web',
            device_token: 'user',

        }).then(async (data) => {
            setLoader(false)
            if (data.status) {
                // await createUser(data.data.user).then(() => {
                toast.success(data.message);
                history.push('/manage-users');
                // })
            } else {
                if (data.status === false && data.message.includes('Duplicate entry')) {
                    setError('email',{message:'This email is already registered.'});
                } else {
                    setError('email',{message:''});
                }
                setLoader(false)

                toast.error(data.message);
            }
        }).catch((error) => {
            setLoader(false);
            if (error.response.status === 401) {
                EmptyLocalStorage();
                history.push('/');
            } else {
                if (error.response.data.status === false && error.response.data.message && error.response.data.data && Array.isArray(error.response.data.data)) {
                    const errorMessages = error.response.data.data.map((message) => message).join(", ");
                    toast.error(`${error.response.data.message}: ${errorMessages}`);
                } else {
                    toast.error(error.response.data.message);
                }
            }
        })
    };


    const uploadFile = async (fileInput) => {
        // Convert FileList object to array of files
        const files = Array.from(fileInput);

        // Check if any files are selected
        if (files.length === 0) {
            setIsUpload(false);
            return; // No files selected, exit the function
        }

        // Process each selected file
        files.forEach((file) => {
            // Validate file type
            if (!isValidFileType(file.type)) {
                toast.error('Invalid file type. Please select a valid image file.');
                return; // Invalid file type, skip to the next file
            }

            // Set the image URL and file in state
            const fileUrl = URL.createObjectURL(file);
            setImage(prevImage => [...prevImage, fileUrl]);
            setFile(prevFile => [...prevFile, file]);
        });


        setIsUpload(true);
    };


    const isValidFileType = (fileType) => {
        // Define an array of valid file types
        const validFileTypes = ['image/jpeg', 'image/png', 'image/jpg']; // Add more valid file types if needed

        // Check if the file type is included in the array of valid file types
        return validFileTypes.includes(fileType);
    };

    const removeImage = (indexToRemove) => {
        const filteredImages = image.filter((_, index) => index !== indexToRemove);
        const filteredFiles = file.filter((_, index) => index !== indexToRemove);
        setImage(filteredImages);
        setFile(filteredFiles);
    };

    const validateMedia = (fileList) => {
        // Convert FileList object to array of files
        const files = Array.from(fileList);

        if (files.length === 0) {
            return 'Image is required';
        }

        let totalSize = 0;

        // Calculate the total size of all selected files
        files.forEach((file) => {
            totalSize += file.size;
        });

        if (totalSize > 5 * 1024 * 1024) {
            return 'Total image size should be less than 5MB';
        }

        return true; // Validation passed
    };





    const optionsMusic = (selectedOptions) => {
        setSelectedMusicType(selectedOptions || []);

        if(!selectedOptions){
            setError('music_type' ,{message:"Music type is required"})
        }else{
            clearErrors('music_type')
        }
    }

    const optionsBusinessType = (selectedOptions) => {
        setSelectedBusinessType(selectedOptions || []);

        if(!selectedOptions){
            setError('business_type' ,{message:"Business type is required"})
        }else{
            clearErrors('business_type')
        }
    }

    const optionsEntertainment = (selectedOptions) => {
        setSelectedEntertainment(selectedOptions || []);

        if(!selectedOptions){
            setError('entertainment_type' ,{message:"Entertainment type is required"})
        }else{
            clearErrors('entertainment_type')
        }
    }
    const optionsCapacity = (selectedOptions) => {
        setSelectedCapacity(selectedOptions || []);
    }

    return (
        <div className="AddNewUser">
            {/*<BreadcrumbsComponent />*/}
            <h3 className="page-heading">Add Business</h3>
            <Row>
                <Col xs={12} sm={12} md={10} lg={12} xl={12}>
                    <Form encType="multipart/form-data" className="formAddUser" autoComplete="off" role="presentation" onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col xs={12} sm={12} md={4} lg={3} xl={3}>
                                <Form.Group className="mb-3" controlId="image">
                                    <Form.Label>Upload Images*<Form.Text className=" text-muted" style={{fontSize:".675em"}}>(Max upload file size: 5 MB)</Form.Text> </Form.Label>
                                    <input
                                        {...register('media',{
                                            validate: validateMedia // Use the custom validation function
                                        })}
                                        multiple
                                        onChange={(e) => uploadFile(e.target.files)}
                                        type='file'
                                        accept={'.jepg,.jpg,.png'}
                                        class="custom-file-input"
                                        id='image'
                                    />

                                    <br/>
                                    {errors.media && <Form.Text
                                        className=" validationText hasError">{errors.media.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                        </Row>
                        {(isUpload && image?.length > 0) ?
                            <Row>
                                <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                    <ul className={" uploaded-imgs"}>
                                        {image?.map((item, index) => (
                                            <li  key={index}>
                                                <img src={item} width="50%" height="50%" alt={`Image ${index}`} />
                                                <Button size="sm" onClick={() => removeImage(index)}><CrossIcon/></Button>
                                            </li>
                                        ))}


                                    </ul>
                                </Col>
                            </Row>
                            :  ""
                        }
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Business Name*</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Business Name"
                                        id={"formSignUpName"}
                                        {...register('business_name', {
                                            required: {
                                                value: "required",
                                                message: "Business Name is required"
                                            },
                                            pattern:{
                                                value:/^(?!\s).*$/,
                                                message:"Business Name should not start with a space"
                                            },
                                            minLength: {
                                                value: 2,
                                                message: "Min length is 2"
                                            },
                                            maxLength: {
                                                value: 100,
                                                message: "Max length is 100"
                                            },
                                        })} type="text"
                                    />
                                    {errors.business_name && <Form.Text
                                        className="text-muted validationText hasError">{errors.business_name.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="email">
                                    <Form.Label>Business Email*</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Your Email"
                                        autocomplete="off"
                                        {...register('email', {
                                            required: {
                                                value: "required",
                                                message: "Business Email is required"
                                            },
                                            pattern: {
                                                value: /\S+@\S+\.\S+/,
                                                message: "Entered value does not match email format"
                                            },
                                            maxLength: {
                                                value: 100,
                                                message: "Max length is 100"
                                            },
                                        })} type="email" />
                                    {errors.email && <Form.Text
                                        className="text-muted validationText hasError">{errors.email.message}</Form.Text>}
                                </Form.Group>
                            </Col>


                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <div className={"pass-field mb-3"}>
                                    <Form.Label>Password*</Form.Label>
                                    <InputGroup controlId="passowrd">
                                        <FormControl
                                            placeholder="Enter Password"
                                            autoComplete={"off"}
                                            maxLength={255}
                                            minLength={8}
                                            {...register('password', {
                                                required: {
                                                    value: "required",
                                                    message: "Password is required"
                                                },
                                                pattern: {
                                                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                                                    message: 'Password should contain a mix of uppercase and lowercase letters, numbers, and special characters'
                                                },
                                                minLength: {
                                                    value: 8,
                                                    message: "Min length is 8"
                                                },
                                                maxLength: {
                                                    value: 255,
                                                    message: "Max length is 255"
                                                }
                                            })}
                                            type={pass ? "password" : "text"} />
                                        <Button variant="outline-secondary" id="button-addon2" onClick={() => TogglePass(!pass)}>
                                            {
                                                pass ? <FaEye /> : <FaEyeSlash />
                                            }
                                        </Button>
                                    </InputGroup>
                                    {errors.password && <Form.Text className="text-muted validationText hasError">{errors.password.message}</Form.Text>}
                                </div>
                            </Col>

                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <div className={"pass-field mb-3"}>
                                    <Form.Label>Confirm Password*</Form.Label>
                                    <InputGroup controlId="password_confirmation">
                                        <Form.Control
                                            placeholder="Enter Password Again"
                                            maxLength={25}
                                            minLength={3}
                                            {...register('password_confirmation', {
                                                required: {
                                                    value: "required",
                                                    message: "Confirm Password is required"
                                                },
                                                minLength: {
                                                    value: 8,
                                                    message: "Min length is 8"
                                                },
                                                validate: password.current ? value => value === password.current || "The passwords do not match" : '',
                                                maxLength: {
                                                    value: 255,
                                                    message: "Max length is 255"
                                                }
                                            })}
                                            type={confPass ? "password" : "text"}
                                        />
                                        <Button variant="outline-secondary" id="button-addon2"  onClick={() => ToggleConfPass(!confPass)}>
                                            {
                                                confPass ? <FaEye /> : <FaEyeSlash />
                                            }
                                        </Button>
                                    </InputGroup>
                                    {errors.password_confirmation && <Form.Text className="text-muted validationText hasError">{errors.password_confirmation.message}</Form.Text>}
                                </div>
                            </Col>

                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Business Type*</Form.Label>
                                    <Select
                                        options={businessTypeOption}
                                        className="basic-multi-select"
                                        onChange={optionsBusinessType}
                                        value={selectedBusinessType}
                                        styles={colourStyles}
                                    />
                                    {errors.business_type && <Form.Text
                                        className=" validationText hasError">{errors.business_type.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Business Location*</Form.Label>

                                            <Autocomplete
                                                apiKey={ process.env.REACT_APP_GOOGLE}
                                                placeholder={'Location'}
                                                options={{
                                                    types:['geocode', 'establishment']
                                                }}
                                                // onChange={onChange}
                                                // defaultValue={data?.data?.address && data?.data?.address}
                                                onPlaceSelected={(place) => {
                                                    const lat = place.geometry.location.lat();
                                                    const lng = place.geometry.location.lng();
                                                    const placeId = place.place_id;
                                                    const formatted_address = place.formatted_address;
                                                    console.log({lat:lat,long:lng,place_id:placeId,formatted_address:formatted_address},"Place")
                                                    setLocation({lat:lat,long:lng,place_id:placeId,formatted_address:formatted_address})

                                                }}
                                                className={'form-control'}
                                                id={'floatingAddress'}
                                            />

                                    {errors.address && (
                                        <Form.Text>{errors.address.message}</Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Music Type</Form.Label>
                                    <Select
                                        options={musicOption}
                                        className="basic-multi-select"
                                        onChange={optionsMusic}
                                        value={selectedMusicType}
                                        isMulti
                                        styles={colourStyles}
                                    />
                                    {errors.music_type && <Form.Text
                                        className=" validationText hasError">{errors.music_type.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Entertainment Type*</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Entertainment Type"
                                        autocomplete="off"
                                        {...register('entertainment_type', {
                                            required: {
                                                value: "required",
                                                message: "Entertainment type is required"
                                            },
                                            pattern:{
                                                value:/^(?!\s).*$/,
                                                message:"Entertainment type should not start with a space"
                                            },
                                            minLength: {
                                                value: 2,
                                                message: "Min length is 2"
                                            },
                                            maxLength: {
                                                value: 100,
                                                message: "Max length is 100"
                                            },
                                        })} type="email" />
                                    {errors.entertainment_type && <Form.Text
                                        className=" validationText hasError">{errors.entertainment_type.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Maximum Capacity</Form.Label>
                                    <Select
                                        options={capacityOption}
                                        className="basic-multi-select"
                                        onChange={optionsCapacity}
                                        value={selectedCapacity}
                                        isMulti
                                        styles={colourStyles}
                                    />
                                    {errors.maxParticipants && <Form.Text
                                        className=" validationText hasError">{errors.maxParticipants.message}</Form.Text>}
                                </Form.Group>
                            </Col>

                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Age Limit*</Form.Label>
                                    <Form.Control
                                        placeholder="Min Age !8"
                                        id={"formSignUpName"}
                                        {...register('age_limit', {
                                            required: {
                                                value: "required",
                                                message: "Age Limit is required"
                                            },
                                            pattern:{
                                                value:/^(?!\s).*$/,
                                                message:"Age Limit should not start with a space"
                                            },
                                            minLength: {
                                                value: 2,
                                                message: "Min length is 2"
                                            },
                                            maxLength: {
                                                value: 100,
                                                message: "Max length is 100"
                                            },
                                        })} type="text"
                                    />
                                    {errors.age_limit && <Form.Text
                                        className="text-muted validationText hasError">{errors.age_limit.message}</Form.Text>}

                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                {loader ? <div className="spin-loader">
                                        <FadeLoader color={"rgb(139, 77, 198)"} height={10} />
                                    </div> :
                                    <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"}
                                           value={"Add Business"} />}
                            </Col>


                        </Row>
                    </Form>

                </Col>
            </Row>

        </div>
    )
}

export default AddBusiness;