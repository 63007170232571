import React, {useEffect, useState} from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { useForm } from "react-hook-form";
import { useHistory } from 'react-router-dom'
import { FadeLoader } from 'react-spinners';
import {SendNotification} from "../../services/notification.service";
import {toast} from "react-toastify";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {GetSettings, UpdateCommissionPercent} from "../../services/payment.service";


// Update: API is pending of this module.


function EditComissionPercent(props) {
    let history = useHistory();
    const [loader, setLoader] = useState(false);
    const { register, reset, setError, clearErrors,setValue, handleSubmit, formState: { errors } } = useForm({
        mode: "onBlur",
    });


    useEffect(()=>{
        getSettings()
    },[])

    const getSettings = async ()=>{
        await GetSettings().then(async (data) => {
            if (data.success) {
                setValue('commission_percent',data?.settings?.commission_percent)
                toast.success('Commission % retrieved successfully');
            } else {
                toast.error(data.message);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    }

    const onSubmit = async (data) => {
        setLoader(true)
        let dt = {
            commission_percent: data.commission_percent,
        }

        await UpdateCommissionPercent(dt).then(async (data) => {
            setLoader(false)
            if (data.success) {
                toast.success("Commission % has been updated successfully");
            } else {
                setLoader(false)
                toast.error(data.message);
            }
        }).catch((error) => {
            setLoader(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    };


    return (
        <div className="AddNewUser">
            {/*<BreadcrumbsComponent />*/}
            <h3 className="page-heading">Edit Commission %</h3>
            <Row>
                <Col xs={12} sm={12} md={12} lg={6} xl={4}>
                    <Form encType="multipart/form-data" className="formAddUser" autoComplete="off" role="presentation" onSubmit={handleSubmit(onSubmit)}>

                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Comission % <span className="text-danger">*</span></Form.Label>
                                    <Form.Control
                                        placeholder="Enter Comission %"
                                        id={"formSignUpName"}
                                        maxLength={25}
                                        minLength={1}
                                        {...register("commission_percent", {
                                            required: "Commission % is required",
                                            pattern: {
                                                value: /^\d*\.?\d*$/,
                                                message: "Commission % should not start with a space and should be a number"
                                            },
                                            validate: {
                                                positiveNumber: value => parseFloat(value) > 0 || "Commission % must be a positive number",
                                                validNumber: value => !isNaN(parseFloat(value)) || "Invalid commission %"
                                            }
                                        })}
                                        type="text"
                                    />
                                    {errors.commission_percent && (
                                        <Form.Text className="text-muted validationText hasError">
                                            {errors.commission_percent.message}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                {loader ? <div className="spin-loader">
                                    <FadeLoader color={"rgb(139, 77, 198)"} height={10} />
                                </div> :
                                    <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"}
                                        value={"Update"} />}
                            </Col>


                        </Row>
                    </Form>

                </Col>
            </Row>

        </div>
    )
}

export default EditComissionPercent
