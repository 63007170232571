import { apiUrl } from "../utils/constants";
import { GET } from "./api.service.wrapper";

export const GetDashboardData = async () => {
  let response = "";
  response = await GET(apiUrl.dashboard);
  return response;
};


// ================= Dashboard Users Insights API =================

export const GetUsersReport = async (params) => {
  let response = "";
  response = await GET(apiUrl.reports, params);
  return response;
};
